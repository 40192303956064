import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from "axios";
import HttpService from 'src/services/HttpService';
import {ReactNode} from "react";
import orderBy from 'lodash/orderBy';
import icons from './icons'
export interface IMenuItem {
    url?: string;
    icon?: ReactNode;
    badge?: string;
    items?: IMenuItem[];
    activeLabel: string;
}

export interface IMenuItems {
    items: IMenuItem[];
    groupLabel: string;
}
export interface IMenuStore {
    list: IMenuItems[];
    loading: boolean;
    error: string;
}

export const fetchMenus = createAsyncThunk('menu/fetchList', ()=>{
    return axios
        .get(`/api/menus`, {headers: {...HttpService.header()}})
        .then(response=>{
            const roles = HttpService.roles();

            const filteredMenus = response.data.data.filter(v=>v.userRoles.find(w=>!!roles.find(y=>y===w)))
            const orderedData = orderBy(filteredMenus, ['groupLabel', 'activeLabel'], ['asc', 'asc'])
            let items = []
            let parents = []

            const lengthOfData = orderedData.length;

            orderedData.reduce((l, v, i)=>{
                if (i===0 ){
                    items.push({activeLabel: v?.activeLabel,
                                url: v?.url,
                                userRoles: v?.userRoles,
                                icon: icons[i].icon
                    })
                    if ((lengthOfData-1)===i){
                        parents.push({groupLabel: v?.groupLabel,
                            items: items})
                        items=[]
                    }
                }
                if ((i !== 0) && (l?.groupLabel === v?.groupLabel)){
                    items.push({activeLabel: v?.activeLabel,
                        url: v?.url,
                        userRoles: v?.userRoles,
                        icon: icons[i].icon
                    })
                    if ((lengthOfData-1)===i){
                        parents.push({groupLabel: v?.groupLabel,
                            items: items})
                        items=[]
                    }
                }
                if(l?.groupLabel && (l?.groupLabel!==v?.groupLabel)) {
                    parents.push({groupLabel: l?.groupLabel,
                        items: items})
                        items = []
                    items.push({activeLabel: v?.activeLabel,
                        url: v?.url,
                        userRoles: v?.userRoles,
                        icon: icons[i].icon
                    })
                    if ((lengthOfData-1)===i){
                        parents.push({groupLabel: v?.groupLabel,
                            items: items})
                    }
                }
                return v;
            }, {});

            return parents;
        })
})

const initialState:IMenuStore = {
    list: [],
    error: '',
    loading: false
}

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setBranchList: (state, action)=>{
            state.list=action.payload;
        }

    },
    extraReducers: (builder) => {
        // list
        builder.addCase(fetchMenus.pending, (state)=>{
            state.loading=true;
            state.error='';
            state.list=[]
        })
        builder.addCase(fetchMenus.fulfilled, (state, action: PayloadAction<IMenuItems[]>) => {
            state.loading=false;
            state.list=action.payload;
            state.error='';
        })
        builder.addCase(fetchMenus.rejected, (state, action)=>{
            state.loading=false;
            state.list=[];
            state.error=action.error.message || 'Something went wrong';
        })
    }
})

export const { setBranchList } = menuSlice.actions;
export default menuSlice.reducer;
