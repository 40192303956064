import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from "axios";
import HttpService from 'src/services/HttpService';
import { ContactPerson } from './MasterdataHotelSlice';
const {REACT_APP_TARGET_SRV} = process.env

export type IMasterdata= {
    id: string;
    uid:string;
    providerName: string;
    description: string;
    streetAddress: string;
    city: string;
    stateOrProvince: string;
    country: string;
    phoneNumber: string;
    contactPersonList: ContactPerson[];
    cp1Name: string;
    cp1Phone: string;
    cp2Name: string;
    cp2Phone: string;
}

export type IPage = {
    limit: number;
    page: number;
    search: string;
}

type uiType = 'LIST' | 'EDIT' | 'ADD';

interface IMasterDataUI {
    mode: uiType;
    isOpen: boolean;
}

interface IMasterdatas {
    loading: boolean;
    list: IMasterdata[];
    error: string;
    ui: IMasterDataUI;
    selected?: IMasterdata;

}

const initialState: IMasterdatas = {
    loading: false,
    error: '',
    list : [],
    ui: {
        mode: 'LIST',
        isOpen: false
    },
}

export const fetchMasterData = createAsyncThunk('masterdatavisaprovider/fetchList', async(payload:IPage,thunkAPI)=>{
    //if limit & page ==0 then display all data
    const url=(payload.limit==0 && payload.page==0)?REACT_APP_TARGET_SRV + '/visa_providers':
                (payload.search!=='')?REACT_APP_TARGET_SRV + '/visa_providers?name=' + payload.search: 
                  REACT_APP_TARGET_SRV + '/visa_providers?page=' + payload.page + '&limit=' + payload.limit;
        return axios
        .get(url, {headers: {...HttpService.header()}})
        .then(response=>{
           
            return response.data.map((v,i)=>{
                return {
                    ...v,
                    uid: v?.id,
                    id: i+1                    
                }
            })
        })
        .catch((err)=>{
            //Checking condition if error from API then display from API, else from Thunk
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.message;
            return thunkAPI.rejectWithValue(ErrCus);
         }) 
})

export const updateMasterData= createAsyncThunk('masterdatavisaprovider/update',  async (payload:IMasterdata,thunkAPI)=>{
    const fileList = []; ///declare filelist Upload


    return await axios
        .put(REACT_APP_TARGET_SRV+`/visa_providers/${payload?.uid}`,{...payload}, {headers: {...HttpService.header()}})
        .then(async response=>{
            return response.data;
        })
        .catch((err)=>{
            //Checking condition if error from API then display from API, else from Thunk
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.messages.join('\n');
            return thunkAPI.rejectWithValue(ErrCus);
        })
})

export const addMasterData = createAsyncThunk('masterdatavisaprovider/add',  async (payload:IMasterdata,thunkAPI)=>{
    return await axios
        .post(REACT_APP_TARGET_SRV+`/visa_providers`,{...payload}, {headers: {...HttpService.header()}})
        .then(async response=>{
            return response.data;
        })
        .catch((err)=>{
            //Checking condition if error from API then display from API, else from Thunk
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.messages.join('\n');
            return thunkAPI.rejectWithValue(ErrCus);
         })
})

export const MasterDataHotelSlice = createSlice({
    name: 'MasterdataVisaProvider',
    initialState,
    reducers: {
        setMasterdataList: (state, action)=>{
            state.list=action.payload;
        },
        setMasterdataUI: (state, action)=>{
            state.ui = {
                mode: action.payload.mode,
                isOpen: action.payload.isOpen
            };
        },
        setMasterdataSelected: (state, action)=>{

            const {MasterdataSelected, MasterdataList} = action.payload;
            let MasterdataSelectedData = {} as IMasterdata;

            if (MasterdataSelected?.uid)
                MasterdataSelectedData = MasterdataList?.find(v=>
                v?.uid===MasterdataSelected?.uid
            );

            const cpList = MasterdataSelected?.contactPersonList;

            state.selected = {
                ...MasterdataSelectedData,
                cp1Name: cpList && cpList.length > 0 ? cpList[0].fullName : '',
                cp1Phone: cpList && cpList.length > 0 ? cpList[0].mobileNo : '',
                cp2Name: cpList && cpList.length > 1 ? cpList[1].fullName : '',
                cp2Phone: cpList && cpList.length > 1 ? cpList[1].mobileNo : ''
            };
        }
    },
    extraReducers: (builder) => {
        // list
        builder.addCase(fetchMasterData.pending, (state)=>{
            state.loading=true;
            state.error='';
            state.list=[]
        })
        builder.addCase(fetchMasterData.fulfilled, (state, action: PayloadAction<IMasterdata[]>) => {
            state.loading=false;
            state.list=action.payload;
            state.error='';
        })
        builder.addCase(fetchMasterData.rejected, (state, action)=>{
            state.loading=false;
            state.list=[];
            state.error=action.error.message || 'Something went wrong';
        })
        // update
        builder.addCase(updateMasterData.pending, (state)=>{



            state.loading=true;
            state.error='';
        })
        builder.addCase(updateMasterData.fulfilled, (state, action)=>{
            state.loading=false;
            // const index = state.list.findIndex(val => val.id === action.payload.id);
            // state.list[index] = {
            //     ...state.list[index],
            //     ...action.payload
            // }
            state.error='';
        })
        builder.addCase(updateMasterData.rejected, (state, action)=>{
            state.loading=false;
            // state.list=state.list;
            state.error=action.error.message || 'Something went wrong';
        })
        // insert
        builder.addCase(addMasterData.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(addMasterData.fulfilled, (state, action)=>{
            state.loading=false;

            const nextOrderNumber:number = state.list.length+1;
            state.list.push({...action.payload.data, id:nextOrderNumber})
            state.error='';
        })
        builder.addCase(addMasterData.rejected, (state, action)=>{
            state.loading=false;
            // state.list=state.list;
            state.error=action.error.message || 'Something went wrong';
        })
    }
})

export const { setMasterdataList, setMasterdataSelected, setMasterdataUI } = MasterDataHotelSlice.actions;
export default MasterDataHotelSlice.reducer;
