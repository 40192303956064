import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
// import Receipt from "src/reports/Receipt";
import Receipt from "src/reports/Receipt";
import Logout from "src/content/applications/Logout/index";

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages

//const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const CurrentStatus = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

const Messenger = Loader(lazy(() => import('src/content/applications/Messenger')));
const Category = Loader(lazy(() => import('src/content/applications/Products/Category')));
const Branch = Loader(lazy(()=>import("./content/applications/Branch/List")));
const ProductList = Loader(lazy(()=>import("./content/applications/Products/List")));
const Pilgrims = Loader(lazy(()=>import("./content/applications/Jamaah/List")));
const Agents = Loader(lazy(()=>import("./content/applications/Partners/List")));
const UserProfile = Loader(lazy(() => import('src/content/applications/Users/profile')));
const UserSettings = Loader(lazy(() => import('src/content/applications/Users/settings')));
const Userzamzam = Loader(lazy(() => import('src/content/applications/Userzamzam')));
const ProductType = Loader(lazy(() => import('src/content/applications/Products/ProductType')));
const Disbursement = Loader(lazy(()=>import("./content/applications/Disbursement/List")));
const RefundPage = Loader(lazy(()=>import("./content/applications/Refund/page")));
const MasterDataHotel = Loader(lazy(()=>import("./content/applications/MasterdataHotel/List")));
const MasterDataMaskapai = Loader(lazy(()=>import("./content/applications/MasterdataMaskapai/List")));
const MasterDataVisaProvider = Loader(lazy(()=>import("./content/applications/MasterdataVisaProvider/List")));
const MasterDataInsurance = Loader(lazy(()=>import("./content/applications/MasterdataInsurance/List")));
const Login = Loader(lazy(()=>import("./content/applications/Login")));
 
// Components

const Buttons = Loader(lazy(() => import('src/content/pages/Components/Buttons')));
const Modals = Loader(lazy(() => import('src/content/pages/Components/Modals')));
const Accordions = Loader(lazy(() => import('src/content/pages/Components/Accordions')));
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(lazy(() => import('src/content/pages/Components/Badges')));
const Tooltips = Loader(lazy(() => import('src/content/pages/Components/Tooltips')));
const Avatars = Loader(lazy(() => import('src/content/pages/Components/Avatars')));
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')));


const routes: PartialRouteObject[] = [

  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <SidebarLayout />
      },
      {
        path: 'overview',
        element: (
          <Navigate
            to="/"
            replace
          />
        )
      },
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: (
              <Navigate
                to="404"
                replace
              />
            )
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          },
        ]
      },
      {
        path: '*',
        element: <Status404 />
      },
      {
        path: 'login',
        element: <Login />
      },
    ]
  },
  {
    path: 'dashboards',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/dashboards/current-status"
            replace
          />
        )
      },
      {
        path: 'current-status',
        element: <CurrentStatus />
      },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },
  

  {
    path: 'management',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/management/product-categories"
            replace
          />
        )
      },
      {
        path: 'product-categories',
        element: <Category />
      },
      {
        path: 'admins',
        //element: <Admin />
        element:<Userzamzam/>
      },
      {
        path: 'product-types',
        //element: <Admin />
        element:<ProductType/>
      },
      {
        path: 'branches',
        element: <Branch />
      },      {
        path: 'products',
        element: <ProductList />
      },
      {
        path: 'pilgrims',
        element: <Pilgrims />
      },
      {
        path: 'agents',
        element: <Agents />
      },
      {
        path: 'disbursement',
        element: <Disbursement />
      },
      {
        path: 'refunds',
        element: <RefundPage />
      },
      {
        path: 'masterdata/hotel',
        element: <MasterDataHotel/>
      },
      {
        path: 'masterdata/maskapai',
        element: <MasterDataMaskapai/>
      },
      {
        path: 'masterdata/visaprovider',
        element: <MasterDataVisaProvider/>
      },
       {
        path: 'masterdata/insurance',
        element: <MasterDataInsurance/>
      }, 
      {
        path: 'receipts',
        element: <Receipt />
      },
      {
        path: 'logout',
        element: <Logout />
      },
      {
        path: 'profile',
        children: [
          {
            path: '/',
            element: (
              <Navigate
                to="details"
                replace
              />
            )
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          },
        ]
      }
    ]
  },
  {
    path: 'components',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/components/buttons"
            replace
          />
        )
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      },
    ]
  },
  {
    path: '/',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/dashboards/current-status"
            replace
          />
        )
      }
    ],
  },
];

export default routes;
