import { EntryPoint } from './App';
import ReactDOM from 'react-dom';
import 'src/utils/chart';
import * as serviceWorker from './serviceWorker';

import 'nprogress/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';
import HttpService from 'src/services/HttpService';

ReactDOM.render(<EntryPoint />, document.getElementById('root'));

//UserService.initKeycloak(renderApp);
HttpService.configure();
serviceWorker.unregister();
