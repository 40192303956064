import React, {Component} from "react";
import "./receipt.module.css"
import {Box,  Grid, Avatar} from "@mui/material";
import {styled} from "@mui/material/styles";
import Table from "@mui/material/Table";

const pageStyle = `@page {
size: 210mm 148mm;
}
@media print {
@page { size: a5 landscape;
margin: 0mm !important;
}
@media all {
.pagebreak {
display: none;
}
}
}
}`;
const Receipt = (props) => {
  const { printData} = props;

  return (
    <React.Fragment>
      <body className="websiteBody">
      <div className="Page-outer-new">
        <div id="Page" data-name="Page" className="Page-new">
          <div className="Frame-outer">
            <div id="Frame" data-name="A5 - receipt 2" className="Frame-new">
              <Grid container direction={"column"}>
                <Box
                  sx={{
                    marginLeft:"8px",
                    width: "780px",
                    height:"auto",
                    justifyContent: "center"
                  }}
                >
                 {/*    <Grid container direction={"row"} justifyContent="center">
                      <Typography variant={"h2"}>{`Voucher Pendaftaran`}</Typography>
                    </Grid> */}
                  {/*<Grid container direction={"row"} pt={1} justifyContent="center">*/}
                  {/*  <Typography variant={"h4"}>{"No: FPP/20    /   /   "}</Typography>*/}
                  {/*</Grid>*/}
                   <Grid container direction={"row"}  justifyContent="center">
                   <Avatar src="/static/images/logo/hajjumrahicon.png" variant="square"/>
                  </Grid>
                  <div>
                    <Grid container ml={2} mt={1} pr={2} pt={3} direction={"column"}>
                      <Grid container direction={"row"}>
                        <Grid item className="declaration-outer" xs={12}>
                                 <span className="declaration">
                                 Saya yang bertandatangan di bawah ini bermaksud mendaftarkan diri dalam paket Penjualan Haji/Umroh/Wisata Muslim yang disediakan oleh PT Retali Mustajab Travel:
                                 </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                  {/*<div>*/}
                  {/*  <Grid container mt={1} direction={"column"}>*/}
                  {/*    <Grid container direction={"row"}>*/}
                  {/*      <Grid pl={6} item xs={12}>*/}
                  {/*               <span className="Txn-no-heading">*/}
                  {/*               Nama (Sesuai KTP)*/}
                  {/*               </span>*/}
                  {/*      </Grid>*/}
                  {/*      <Grid item xs={0.2}>*/}
                  {/*        <span className="q10-34-am-Sun-14-Jul-date">:</span>*/}
                  {/*      </Grid>*/}
                  {/*      <Grid item xs={4}>*/}
                  {/*         <span className="q10-34-am-Sun-14-Jul-date">*/}
                  {/*         {'transactionrefno'}*/}
                  {/*         </span>*/}
                  {/*      </Grid>*/}
                  {/*      <Grid item xs={4}>*/}
                  {/*      </Grid>*/}
                  {/*    </Grid>*/}
                  {/*  </Grid>*/}

                  {/*</div>*/}

                  {/*<div>*/}
                  {/*  <Grid container pr={2} direction={"column"}>*/}
                  {/*    <Grid container direction={"row"}>*/}
                  {/*      <Grid pl={6} item xs={2}>*/}
                  {/*               <span className="q10-34-am-Sun-14-Jul-date">*/}
                  {/*               Merchant Txn Ref No*/}
                  {/*               </span>*/}
                  {/*      </Grid>*/}
                  {/*      <Grid item xs={0.2}>*/}
                  {/*        <span className="q10-34-am-Sun-14-Jul-date">:</span>*/}
                  {/*      </Grid>*/}
                  {/*      <Grid item xs={4}>*/}
                  {/*               <span className="q10-34-am-Sun-14-Jul-date">*/}
                  {/*               {trxDetail?.merchanttxnrefno}*/}
                  {/*               </span>*/}
                  {/*      </Grid>*/}
                  {/*      <Grid item xs>*/}
                  {/*        <Grid container justifyContent={"flex-end"}>*/}
                  {/*          /!**/}
                  {/*                  <Grid item xs>*!/*/}
                  {/*          <span className="vat-invoice">*/}
                  {/*                     Inv No: {trxDetail?.invoicenumber || ""}*/}
                  {/*                     </span>*/}
                  {/*          /!**/}
                  {/*                  </Grid>*/}
                  {/*                  *!/*/}
                  {/*        </Grid>*/}
                  {/*      </Grid>*/}
                  {/*    </Grid>*/}
                  {/*  </Grid>*/}
                  {/*</div>*/}
                </Box>
              </Grid>
              <div ml>
                <Grid container ml={3} mt={1} direction={"column"}>
                  <Grid container direction={"row"}>
                    {/*
                           <div class="square">
                              */}
                    <Box style={{
                      // border: "1px solid #01AFF0",
                      borderRadius: "12px",
                      marginLeft:"8px",
                      width: "780px",
                      height:"auto"
                    }}>
                      {/*<Grid container ml={1} mt={1} direction={"column"}>*/}
                      {/*  <Grid container direction={"row"}>*/}
                      {/*    <Grid item xs={12}>*/}
                      {/*                 <span className="section-heading" >*/}
                      {/*                 <b>Customer</b>*/}
                      {/*                 </span>*/}
                      {/*    </Grid>*/}
                      {/*  </Grid>*/}
                      {/*</Grid>*/}
                      <div>
                        <div id="No-1009283192-Name-Z">

                          <Grid ml={1} container direction={"column"}>
                            <Grid container  direction={"row"}>
                              <Grid item xs={4}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1">
                                                Nomor Voucher
                                                </span>
                                </Grid>
                              </Grid>
                              <Grid item xs={.2}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1">
                                                :
                                                </span>
                                </Grid>
                              </Grid>
                              <Grid item xs={6}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1-value">
                                                {printData?.voucherCode || "-"}
                                                </span>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container  direction={"row"}>
                              <Grid item xs={4}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1">
                                                NIK
                                                </span>
                                </Grid>
                              </Grid>
                              <Grid item xs={.2}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1">
                                                :
                                                </span>
                                </Grid>
                              </Grid>
                              <Grid item xs={6}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1-value">
                                                {printData?.icNo || "-"}
                                                </span>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container  direction={"row"}>
                              <Grid item xs={4}>
                                <Grid container direction={"column"}>
                                  <span className="heading-content-1">Nama Lengkap (sesuai NIK)</span>
                                </Grid>
                              </Grid>
                              <Grid item xs={0.2}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1">
                                                :
                                                </span>
                                </Grid>
                              </Grid>
                              <Grid item xs={6}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1-value">
                                                {printData?.fullName||"-"}
                                                </span>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container  direction={"row"}>
                              <Grid item xs={4}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1">
                                                No. Handphone
                                                </span>
                                </Grid>
                              </Grid>
                              <Grid item xs={.2}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1">
                                                :
                                                </span>
                                </Grid>
                              </Grid>
                              <Grid item xs={6}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1-value">
                                                {printData?.mobileNo || "-"}
                                                </span>
                                </Grid>
                              {/*  TODO: (usia: tahun) */}
                              </Grid>
                            </Grid>
                            <Grid container  direction={"row"}>
                              <Grid item xs={4}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1">
                                                Paket yang dipilih
                                                </span>
                                </Grid>
                              </Grid>
                              <Grid item xs={.2}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1">
                                                :
                                                </span>
                                </Grid>
                              </Grid>
                              <Grid item xs={6}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1-value">
                                                  {printData?.productName || '-'}
                                                </span>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid container  direction={"row"}>
                              <Grid item xs={4}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1">
                                                Nama Mitra
                                                </span>
                                </Grid>
                              </Grid>
                              <Grid item xs={.2}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1">
                                                :
                                                </span>
                                </Grid>
                              </Grid>
                              <Grid item xs={5.8}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1-value" style={{width: "100px", wordWrap:"break-word"}}>
                                                {printData?.partnerName || "-"}
                                                </span>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container  direction={"row"}>
                              <Grid item xs={4}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1">
                                                No. HP Mitra
                                                </span>
                                </Grid>
                              </Grid>
                              <Grid item xs={.2}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1">
                                                :
                                                </span>
                                </Grid>
                              </Grid>
                              <Grid item xs={6}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1-value">
                                                  {
                                                   printData?.partnerMobileNo || "-"
                                                  }
                                                </span>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container  direction={"row"}>
                              {/* <Grid item xs={4}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1">
                                                Cabang Mitra
                                                </span>
                                </Grid>
                              </Grid>
                              <Grid item xs={.2}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1">
                                                :
                                                </span>
                                </Grid>
                              </Grid>
                              <Grid item xs={6}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1-value">
                                                  {
                                                    printData?.branchName || printData?.branchId
                                                  }
                                                </span>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container  direction={"row"}>
                              <Grid item xs={4}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1">
                                                Kantor Cabang Tempat mendaftar
                                                </span>
                                </Grid>
                              </Grid>
                              <Grid item xs={.2}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1">
                                                :
                                                </span>
                                </Grid>
                              </Grid>
                              <Grid item xs={6}>
                                <Grid container direction={"column"}>
                                                <span className="heading-content-1-value">
                                                  {
                                                    printData?.registeredByBranchName || "-"
                                                  }
                                                </span>
                                </Grid>
                              </Grid> */}
                            </Grid>
                            {/*<Grid container  direction={"row"}>*/}
                            {/*  <Grid item xs={4}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    Pekerjaan*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={.2}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    :*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={6}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1-value">*/}
                            {/*                      {*/}
                            {/*                        "-"*/}
                            {/*                      }*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*</Grid>*/}
                            {/*<Grid container  direction={"row"}>*/}
                            {/*  <Grid item xs={4}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    Email*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={.2}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    :*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={6}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1-value">*/}
                            {/*                      {*/}
                            {/*                        "-"*/}
                            {/*                      }*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*</Grid>*/}
                            {/*<Grid container  direction={"row"}>*/}
                            {/*  <Grid item xs={4}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    No HP (yang ada WA)*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={.2}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    :*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={6}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1-value">*/}
                            {/*                      {*/}
                            {/*                        "-"*/}
                            {/*                      }*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*</Grid>*/}
                            {/*<Grid container  direction={"row"}>*/}
                            {/*  <Grid item xs={4}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    Paket/Jadwal*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={.2}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    :*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={6}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1-value">*/}
                            {/*                      {*/}
                            {/*                        "-"*/}
                            {/*                      }*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*</Grid>*/}
                            {/*<Grid container  direction={"row"}>*/}
                            {/*  <Grid item xs={4}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    Nama Ayah*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={.2}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    :*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={6}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1-value">*/}
                            {/*                      {*/}
                            {/*                        "-"*/}
                            {/*                      }*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*</Grid>*/}
                            {/*<Grid container  direction={"row"}>*/}
                            {/*  <Grid item xs={4}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    Nama Ibu*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={.2}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    :*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={6}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1-value">*/}
                            {/*                      {*/}
                            {/*                        "-"*/}
                            {/*                      }*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*</Grid>*/}
                            {/*<Grid container  direction={"row"}>*/}
                            {/*  <Grid item xs={4}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    Upgrade Kamar*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={.2}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    :*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={6}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1-value">*/}
                            {/*                      {*/}
                            {/*                        "-"*/}
                            {/*                      }*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*</Grid>*/}
                            {/*<Grid container  direction={"row"}>*/}
                            {/*  <Grid item xs={4}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    Trakhir Umrah*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={.2}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    :*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={6}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1-value">*/}
                            {/*                      {*/}
                            {/*                        "-"*/}
                            {/*                      }*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*</Grid>*/}
                            {/*<Grid container  direction={"row"}>*/}
                            {/*  <Grid item xs={4}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    Riwayat Sakit*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={.2}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1">*/}
                            {/*                    :*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*  <Grid item xs={6}>*/}
                            {/*    <Grid container direction={"column"}>*/}
                            {/*                    <span className="heading-content-1-value">*/}
                            {/*                      {*/}
                            {/*                        "-"*/}
                            {/*                      }*/}
                            {/*                    </span>*/}
                            {/*    </Grid>*/}
                            {/*  </Grid>*/}
                            {/*</Grid>*/}
                            <br/>
                          </Grid>
                        </div>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </div>
              <Grid container direction={"column"} pl={1}>
                <Box sx={{
                  // border: "1px solid #01AFF0",
                  // borderRadius: "12px",
                  marginLeft:"4px",
                  width: "840px",
                  height:"auto"
                }}>
                  <div>
                    <Grid container ml={2} mt={1} pr={2} direction={"column"}>
                      <Grid container direction={"row"}>
                        <Grid item className="declaration-outer" xs={12}>
                                 <span className="declaration">
                                   Dengan ini saya mendaftarkan diri sebagai jama'ah untuk pembelian Paket Perjalanan Umrah PT Retali Mustajab Travel, dan akan menerima dan tunduk pada <b>Ketentuan Pendaftaran Perjalanan Umrah Retali</b>.
                                 </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              </Grid>
              <Grid container direction={"column"}>
                <Box sx={{
                  // border: "1px solid #01AFF0",
                  // borderRadius: "12px",
                  marginLeft:"4px",
                  width: "840px",
                  height:"auto"
                }}>
                  <div>
                    <div id="No-1009283192-Name-Z">
                      <Grid ml={3} mt={3} mb={3} container direction={"row"}>
                        {/*<Grid item xs>*/}
                        {/*<Grid container  direction={"row"}>*/}
                          <Grid item  xs textAlign={"center"}>
                            <Grid container direction={"column"}>
                            {/*<Grid container direction={"column"}>*/}
                              <Grid item xs>
                              <b> Jama'ah <br></br></b>
                              </Grid>
                              <Grid item xs pt={8}>
                                (-----------------------)
                              </Grid>
                            </Grid>

                            {/*</Grid>*/}
                          </Grid>
                          <Grid item  xs textAlign={"center"}>
                            {/*<Grid container direction={"column"}>*/}
                            <Grid item xs>
                              <Grid container direction={"column"}>
                                <Grid item xs>
                                  <b> Mitra <br></br></b>
                                </Grid>
                                <Grid item xs pt={8}>
                                  (-----------------------)
                                </Grid>
                              </Grid>
                            </Grid>
                            {/*</Grid>*/}
                          </Grid>
                        {/*</Grid>*/}
                        {/*</Grid>*/}
                        {/*<Grid container  direction={"row"}>*/}
                        {/*  <Grid item  xs={3}>*/}
                        {/*    <span className="heading-content-1"> <b></b></span>*/}
                        {/*  </Grid>*/}
                        {/*  <Grid item  xs={3}>*/}
                        {/*    <span className="heading-content-1"> <b>{trxDetail?.employeename }</b></span>*/}
                        {/*  </Grid>*/}
                        {/*  <Grid item  xs={2}>*/}
                        {/*    <span className="heading-content-1"> <b>{trxDetail?.employeename }</b></span>*/}
                        {/*  </Grid>*/}
                        {/*  <Grid item  xs={4}><b>*/}
                        {/*    <span className="heading-content-1"> <b></b></span>*/}
                        {/*  </b>*/}
                        {/*  </Grid>*/}
                        {/*</Grid>*/}
                      </Grid>
                    </div>
                  </div>
                </Box>
                <Box sx={{
                  // border: "1px solid #01AFF0",
                  // borderRadius: "12px",
                  marginLeft:"4px",
                  width: "840px",
                  height:"auto"
                }}>
                  <div style={{paddingTop:"25px"}}>
                    <Grid container ml={2} mt={1} pr={2} direction={"column"}>
                      <Grid container direction={"row"} justifyContent={"center"}>
                        {/*<Grid item className="declaration-outer" xs={12}>*/}
                                 <b>
                                   PT. RETALI MUSTAJAB TRAVEL
                                 </b>
                        {/*</Grid>*/}
                      </Grid>
                      <Grid container direction={"row"} justifyContent={"center"}>
                        {/*<Grid item className="declaration-outer" xs={12}>*/}
                          Izin PPIU No. U 291 Tahun 2021
                        {/*</Grid>*/}
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      </body>
      <style>{pageStyle}</style>
    </React.Fragment>
  )
}
const PrintingScreen = styled(Table)`
  && {
    //margin-top: 0px;
    /* display: none; */
    @media print {
      display: block;
    }
    -webkit-transform: scale(0.6);
    -moz-transform:scale(0.6);
    filter:progid:DXImageTransform.Microsoft.BasicImage(scale=0.6);
    //.MuiTableCell-root {
    //  border-bottom: none;
    //}
  }
`;

class PaymentReceipt extends Component {
  render() {
    let { printData} = this.props;

    // let printingPages = [];
    // // for (const trxDetailSingle of trxData) {
    // const tempTemplate = (
    //   <Receipt
    //     printData={printData}
    //   />
    // );
    // printingPages.push(tempTemplate);
    // }
    const finalTable = (
      <>
        <PrintingScreen
          role="presentation"
          border="0"
          cellPadding="0"
          cellSpacing="0"
        >
          {/* <>
            {printingPages}
          </> */}
          <Receipt
            printData={printData}
          />
        </PrintingScreen>
      </>
    );

    return finalTable;
  }


}

PaymentReceipt.defaultProps = {
  trxData: []
};

export default PaymentReceipt;
