import { Typography, Avatar, Grid , ThemeProvider} from '@mui/material';
import { useTheme, createTheme } from '@mui/material/styles';
import { func } from 'prop-types';
import React from 'react';
import { useEffect, useState, createRef,useRef } from 'react';
import { useAppSelector } from 'src/store/Hook';




function Profile() {

  const profileData = useAppSelector(state => state.auth)


  const user =
  {
    branchName: profileData.branchName,
    // name: profileData.fullName,
    name: profileData.name,
    role: profileData.role,
    username: profileData.username,
    avatar: '/static/images/logo/baitullah.png'
    // avatar: '/static/images/logo/retali-white.png'
  };

  
const theme = useTheme();
const theme2 = createTheme();



theme2.typography.h4 = {
  //fontSize: '0.5rem',
 /*  '@media (min-width:600px)': {
    fontSize: '0.5rem',
  },  */
  '@media (max-width:600px)': {
    fontSize: '0.4rem',
  },
  [theme2.breakpoints.up('md')]: {
    fontSize: '0.9rem',
  },
};

  return (
    <Grid container alignItems="center">
      {(user.role)?<>
        <Grid item xl={1} lg={1} sm={3} xs={3} >
          <Avatar
            sx={{ mr: 2, width: theme.spacing(7), height: theme.spacing(7) }}
            variant="square"
            alt={user.name}
            src={user.avatar}
          />
       </Grid>
       <Grid item xl={11} lg={11} sm={9} xs={9} p={"2em"}>
          <Typography variant="h5" component="h5" gutterBottom sx={{color: theme.palette.primary.main}}>
            Selamat Datang di {user.branchName?user.branchName:"Kantor Pusat"}
          </Typography>
          <ThemeProvider theme={theme2}>
            <Typography variant="h4"> 
              Anda Login sebagai<b> {user.name} </b>, dengan hak akses <b>( {user.role} ) </b>         
            </Typography> 
          </ThemeProvider>
        </Grid>
        </>:
        <Typography variant="h4" sx={{ color: "red" }} >
        <b>PERHATIAN ....!! KONEKSI/JARINGAN ERROR. SILAHKAN LOGIN ULANG</b>         
        </Typography> 
      }
    </Grid>
    
  );
}

export default Profile;
