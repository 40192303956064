import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import HttpService from 'src/services/HttpService';
import { IProduct } from './ProductSlice';
import { IPartner } from './PartnerSlice';
import uploadFile from '../services/uploadFile';
const { REACT_APP_TARGET_SRV } = process.env;

export type ICustomer = {
  id: string;
  uid: string;
  voucherCode: string;
  voucherExpiredOn: Date;
  fullName: string;
  icNo: string;

  firstName: string;
  lastName: string;
  maritalStatus: string;
  healthCondition: string;
  downPayment: number;
  branchName: string;
  partnerId: any;
  partnerName: string;
  partner: IPartner;

  wni: boolean;
  mobileNo: string;
  gender: boolean;
  education: string;
  education1: string;

  fatherName: string;
  emergencyContact: string;
  emergencyContactPhoneNo: string;

  identityType: string;
  identityNo: string;

  icFileOri: File;
  icFile: string;
  icFileType: string;
  icFileSize: number;

  kkFileOri: File;
  kkFile: string;
  kkFileType: string;
  kkFileSize: number;

  passportFileOri: File;
  passportFile: string;
  passportFileType: string;
  passportFileSize: number;

  vaccineCertFileOri: File;
  vaccineCertFile: string;
  vaccineCertFileType: string;
  vaccineCertFileSize: number;

  vaccineCert2FileOri: File;
  vaccineCert2File: string;
  vaccineCert2FileType: string;
  vaccineCert2FileSize: number;

  vaccineCert3FileOri: File;
  vaccineCert3File: string;
  vaccineCert3FileType: string;
  vaccineCert3FileSize: number;

  passportSecondPageFileOri: File;
  passportSecondPageFile: string;
  passportSecondPageFileType: string;
  passportSecondPageFileSize: number;

  yellowBookFileOri: File;
  yellowBookFile: string;
  yellowBookFileType: string;
  yellowBookFileSize: number;

  photoFileOri: File;
  photoFile: string;
  photoFileType: string;
  photoFileSize: number;

  arrivalBoardingPassFileOri: File;
  arrivalBoardingPassFile: string;
  arrivalBoardingPassFileType: string;
  arrivalBoardingPassFileSize: number;

  infantPassportFileOri: File;
  infantPassportFile: string;
  infantPassportFileType: string;
  inrantPassportFileSize: number;

  downPaymentFileOri: File;
  downPaymentFile: string;
  downPaymentFileType: string;
  downPaymentFileSize: number;

  productId: string;
  productData: IProduct;

  productName: string;
  branchNameProduct: string;

  infantName: string;
  infantNik: string;
  infantDob: Date;
  infantChargedAmount: number;

  upgradedHotelName: string;
  upgradedHotelStar: string;
  upgradedHotelRoomType: string;
  upgradedHotelPrice: number;

  upgradedAirlineName: string;
  upgradedAirlineCode: string;
  upgradedAirlineClass: string;
  upgradedAirlinePrice: number;

  specialPriceDescription: string; // other special requests
  specialPrice: number; // used for other special requests

  agreedProductPrice: number;

  categoryId: string;
  categoryName: string;
  departureDate: string;
  productStatus: string;
  email: string;
  placeOfBirth: string;
  dateOfBirth: Date;
  occupation: string;
  mahram: string;
  mahramRelationship: string;
  streetAddress: string;
  city: string;
  province: string;
  district: string;
  subDistrict: string;
  postalCode: string;

  visaProvider: string;
  visaNo: string;
  visaName: string;
  visaIssuedOn: Date;
  visaIssuedAt: string;
  visaExpiredAt: Date;

  insurance: string;
  insurancePolicyNo: string;
  insuranceCustomerName: string;
  policyEntryDate: Date;
  policyStartingDate: Date;
  policyEndingDate: Date;

  passportIssuedAt: string;
  passportName: string;
  passportNo: string;
  nameOnPassport: string;
  passportIssuedOn: Date;
  passportExpiredOn: Date;
  kkNo: string;

  //payment
  paidAmount: number;
  discountValue?: number
  discountType?: 'percent' | 'amount',
  description: string;
  paymentAttachmentFileOri: File;
  paymentAttachmentFile: string;
  paymentAttachmentFileType: string;
  paymentAttachmentFileSize: number;

  paymentCompleted: string;
  manasikCompleted: string;
  siskopatuhCompleted: string;
  amenitiesCompleted: string;
  takeOff: string;
  journeyCompleted: string;

  branchId: string;
  customerStatus: string;
  tourLeader: boolean;
  nonKafalah: boolean;

  waitingForApproval?: number;
  roomsAssigned: number;
  unassignedRooms: number;

  deleted?: boolean
};

export type IPage = {
  limit: number;
  page: number;
  search: string;
  voucher: string;
  product: string;
  patner: string;
  branch: string;
};

type uiType = 'LIST' | 'EDIT' | 'ADD' | 'PAYMENT';

interface ICustomerUI {
  mode: uiType;
  isOpen: boolean;
}

interface ICustomers {
  loading: boolean;
  list: ICustomer[];
  error: string;
  ui: ICustomerUI;
  selected?: ICustomer;
  customerByVoucher?: any;
  customerPaymentHistory?: any;
}

const initialState: ICustomers = {
  loading: false,
  error: '',
  list: [],
  ui: {
    mode: 'LIST',
    isOpen: false
  },
  customerByVoucher: [],
  customerPaymentHistory: []
};

export const fetchCustomers = createAsyncThunk(
  'customer/fetchList',
  async (payload: IPage, thunkAPI) => {
    let url = `${REACT_APP_TARGET_SRV}/customers`;
    if (payload.search !== '') {
      url += `?name=${payload.search}`;
    } else if (payload.voucher !== '') {
      url += `/${payload.voucher}`;
    } else if (payload.product !== '') {
      url += `/products/${payload.product}`;
    } else if (payload.patner !== '') {
      url += `/partners/${payload.patner}`;
    } else if (payload.branch !== '') {
      url += `/branches/${payload.branch}`;
    }

    if (payload.search === '') {
      url += `?page=${payload.page ? payload.page : 1}&limit=${
        payload.limit ? payload.limit : 5
      }`;
    }

    return await axios
      .get(url, { headers: { ...HttpService.header() } })
      .then((response) => {
        let result = [];
        if (response.data && response.data !== '') {
          const dataMap = response.data;
          if (payload.voucher === '') {
            result = dataMap.map((v, i) => constructCustomerRecords(v, i + 1));
            return result;
          } // End of Searching by Voucher
          result.push(constructCustomerRecords(dataMap, 1));
        }
        return result;
      })
      .catch((err) => {
        const ErrCus =
          err.response.data === undefined || err.response.data === null
            ? err.message
            : err.response.data.message;
        return thunkAPI.rejectWithValue(ErrCus);
      });
  }
);

const constructCustomerRecords = (data, recordNumber: number) => {
  return {
    ...data,
    uid: data.id,
    id: recordNumber,
    productId: {
      uid: data?.productId,
      productName: data?.productName
    },
    partnerId: {
      uid: data?.partnerId,
      fullName: data?.partnerName
    },
    productName: data?.productName,
    branchName: data?.branchName,
    icFile: data?.icFileName,
    kkFile: data?.kkFileName,
    passportFile: data?.passportFileName,
    passportSecondPageFile: data?.passportSecondPageFileName,
    vaccineCertFile: data?.vaccineCertFileName,
    vaccineCert2File: data?.vaccineCert2FileName,
    vaccineCert3File: data?.vaccineCert3FileName,
    yellowBookFile: data?.yellowBookFileName,
    photoFile: data?.photoFileName,
    arrivalBoardingPassFile: data?.arrivalBoardingPassFile,
    infantPassportFile: data?.infantPassportFile,
    //payment
    paidAmount: '',
    description: '',
    paymentAttachmentFile: '',
    customerStatus: data?.customerStatus
  };
};

export const fetchCustomersPayment = createAsyncThunk(
  'customer/fetchListPaymentHistory',
  async (customerId: string) => {
    return await axios
      .get(REACT_APP_TARGET_SRV + `/customers/payments/${customerId}`, {
        headers: { ...HttpService.header() }
      })
      .then((response) => {
        return response.data.map((v, i) => {
          return {
            ...(v || {}),
            id: i + 1,
            uid: v?.id,
            paidAmount: v?.paidAmount,
            remainingAmount: v?.remainingAmount,
            paymentAttachmentFile: v?.paymentAttachmentFile,
            description: v?.description,
            paymentDate: v?.paymentDate,
            fullName: v?.customer?.fullName,
            productName: v?.customer?.product?.productName,
            productPrice: v?.customer?.product?.price,
            downPayment: v?.customer?.downPayment,
            status: v?.status
          };
        });
      });
  }
);

export const getCustomerByVoucher = createAsyncThunk(
  'customer/getByVoucher',
  async (payload: ICustomer) => {
    return await axios
      .get(REACT_APP_TARGET_SRV + `/customers/${payload?.voucherCode}`, {
        headers: { ...HttpService.header() }
      })
      .then((response) => {
        let v = response.data;
        return {
          ...v,
          uid: v.id,
          id: 1,
          cpName: v?.contactPersonName,
          cpMobileNo: v?.contactPersonMobileNo,
          bankAccountCustomer: v?.bankAccountOfficeCustomer,
          productId: {
            uid: v?.productId,
            productName: v?.productName
          },
          partnerId: {
            id: v?.partner?.id,
            fullName: v?.partner?.fullName,
            mobileNo: v?.partner?.mobileNo
          },
          productName: v?.productName,
          branchNameProduct: v?.product?.branch.name,

          icFile: v?.icFileName,
          kkFile: v?.kkFileName,
          passportFile: v?.passportFileName,
          passportSecondPageFile: v?.passportSecondPageFileName,
          vaccineCertFile: v?.vaccineCertFileName,
          yellowBookFile: v?.yellowBookFileName,
          paidAmount: '',
          description: '',
          paymentAttachmentFile: ''
        };
      });
  }
);

export const updateCustomer = createAsyncThunk(
  'customer/update',
  async (payload: ICustomer, thunkAPI) => {
    const fileList = []; ///declare filelist Upload

    //set constant for upload file name
    if (payload.icFileOri) {
      fileList.push('icFileOri');
    }
    if (payload.kkFileOri) {
      fileList.push('kkFileOri');
    }
    if (payload.photoFileOri) {
      fileList.push('photoFileOri');
    }
    if (payload.vaccineCertFileOri) {
      fileList.push('vaccineCertFileOri');
    }
    if (payload.vaccineCert2FileOri) {
      fileList.push('vaccineCert2FileOri');
    }
    if (payload.vaccineCert3FileOri) {
      fileList.push('vaccineCert3FileOri');
    }
    if (payload.passportSecondPageFileOri) {
      fileList.push('passportSecondPageFileOri');
    }
    if (payload.yellowBookFileOri) {
      fileList.push('yellowBookFileOri');
    }
    if (payload.passportFileOri) {
      fileList.push('passportFileOri');
    }
    if (payload.arrivalBoardingPassFileOri) {
      fileList.push('arrivalBoardingPassFileOri');
    }
    if (payload.infantPassportFileOri) {
      fileList.push('infantPassportFileOri');
    }
    if (payload.downPaymentFileOri) {
      fileList.push('downPaymentFileOri');
    }

    //change the filename based on filename and that ext
    if (!!fileList.length) {
      for (let i in fileList) {
        let fileNameAndExt = payload[`${fileList[i]}`]?.name.split('.');
        fileNameAndExt =
          fileList[i] + '.' + fileNameAndExt[fileNameAndExt.length - 1];
        fileNameAndExt = fileNameAndExt.replace('Ori', '');

        payload[fileList[i].replace('Ori', '')] = fileNameAndExt;
      }
    }

    return await axios
      .put(
        REACT_APP_TARGET_SRV + `/customers/${payload?.voucherCode}`,
        { ...payload },
        { headers: { ...HttpService.header() } }
      )
      .then(async (response) => {
        await uploadFile(payload, fileList, 'customers');
        return response.data;
      })
      .catch((err) => {
        //Checking condition if error from API then display from API, else from Thunk
        const ErrCus =
          err.response.data === undefined || err.response.data === null
            ? err.message
            : err.response.data.messages.join('\n');
        return thunkAPI.rejectWithValue(ErrCus);
      });
  }
);

interface IAddPayload {
  categoryName: string;
  agentFee: number;
  referralFee: number;
}

export const addCustomer = createAsyncThunk(
  'customer/add',
  async (payload: IAddPayload, thunkAPI) => {
    return await axios
      .post(
        REACT_APP_TARGET_SRV + `/customers`,
        { ...payload },
        { headers: { ...HttpService.header() } }
      )
      .then(async (response) => {
        return response.data;
      })
      .catch((err) => {
        //Checking condition if error from API then display from API, else from Thunk
        const ErrCus =
          err.response.data === undefined || err.response.data === null
            ? err.message
            : err.response.data.messages.join('\n');
        return thunkAPI.rejectWithValue(ErrCus);
      });
  }
);

interface IAddPayloadPayment {
  uid: string;
  voucherCode: string;
  paidAmount: number;
  description: string;
  paymentAttachmentFile: string;
  paymentAttachmentFileOri: File;
}

export const addCustomerPayment = createAsyncThunk(
  'customer/addPayment',
  async (payload: IAddPayloadPayment) => {
    const fileList = []; ///declare filelist Upload

    //set constant for upload file name
    if (payload.paymentAttachmentFile) {
      fileList.push('paymentAttachmentFileOri');
    }

    //change the filename based on filename and that ext
    for (let i in fileList) {
      let fileNameAndExt = payload[`${fileList[i]}`]?.name.split('.');
      fileNameAndExt =
        fileList[i] + '.' + fileNameAndExt[fileNameAndExt.length - 1];
      fileNameAndExt = fileNameAndExt.replace('Ori', '');

      payload[fileList[i].replace('Ori', '')] = fileNameAndExt;
    }

    return await axios
      .post(
        REACT_APP_TARGET_SRV + `/customers/payments/${payload?.voucherCode}`,
        { ...payload },
        { headers: { ...HttpService.header() } }
      )
      .then(async (response) => {
        const realPayload = {
          ...payload,
          uid: response?.data?.data?.id
        };
        await uploadFile(realPayload, fileList, 'customers/payments');
        return response.data;
      });
  }
);

export const customerSlice = createSlice({
  name: 'Customer',
  initialState,
  reducers: {
    setCustomerList: (state, action) => {
      state.list = action.payload;
    },
    setCustomerUI: (state, action) => {
      state.ui = {
        mode: action.payload.mode,
        isOpen: action.payload.isOpen
      };
    },
    setCustomerSelected: (state, action) => {
      const { customerSelected, customerList } = action.payload;
      let customerSelectedData = {} as ICustomer;
      if (customerSelected?.voucherCode)
        customerSelectedData = customerList?.find(
          (v) => v?.voucherCode === customerSelected?.voucherCode
        );

      state.selected = { ...customerSelectedData };
    }
  },
  extraReducers: (builder) => {
    // list
    builder.addCase(fetchCustomers.pending, (state) => {
      state.loading = true;
      state.error = '';
      state.list = [];
    });
    builder.addCase(
      fetchCustomers.fulfilled,
      (state, action: PayloadAction<ICustomer[]>) => {
        state.loading = false;
        state.list = action.payload;
        state.error = '';
      }
    );
    builder.addCase(fetchCustomers.rejected, (state, action) => {
      state.loading = false;
      state.list = [];
      state.error = action.error.message || 'Something went wrong';
    });
    // update
    builder.addCase(updateCustomer.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(updateCustomer.fulfilled, (state, action) => {
      state.loading = false;
      const index = state.list.findIndex((val) => val.id === action.payload.id);
      state.list[index] = {
        ...state.list[index],
        ...action.payload
      };
      state.error = '';
    });
    builder.addCase(updateCustomer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Something went wrong';
    });
    // insert
    builder.addCase(addCustomer.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(addCustomer.fulfilled, (state, action) => {
      state.loading = false;

      // const nextOrderNumber:number = state.list.length+1;
      // state.list.push({...action.payload.data, id:nextOrderNumber})
      state.error = '';
    });
    builder.addCase(addCustomer.rejected, (state, action) => {
      state.loading = false;
      // state.list=state.list;
      state.error = action.error.message || 'Something went wrong';
    });
    // get customer by voucher
    builder.addCase(getCustomerByVoucher.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getCustomerByVoucher.fulfilled, (state, action) => {
      state.loading = false;
      state.customerByVoucher = action?.payload;
      state.error = '';
    });
    builder.addCase(getCustomerByVoucher.rejected, (state, action) => {
      state.loading = false;
      // state.list=state.list;
      state.error = action.error.message || 'Something went wrong';
    });

    // get customer Payment Customer
    builder.addCase(fetchCustomersPayment.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(fetchCustomersPayment.fulfilled, (state, action) => {
      state.loading = false;
      state.customerPaymentHistory = action?.payload;
      state.error = '';
    });
    builder.addCase(fetchCustomersPayment.rejected, (state, action) => {
      state.loading = false;
      // state.list=state.list;
      state.error = action.error.message || 'Something went wrong';
    });
  }
});

export const { setCustomerList, setCustomerSelected, setCustomerUI } =
  customerSlice.actions;
export default customerSlice.reducer;
