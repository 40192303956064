import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from "axios";
import HttpService from 'src/services/HttpService';
import {IBranch} from "./BranchSlice";
import uploadFile from "../services/uploadFile";
const {REACT_APP_TARGET_SRV} = process.env

export type IMasterdataMaskapai= {
    id: string;
    uid:string;
    airlineCode:string;
    name: string;
    description: string;
    country: string;
    phoneNumber: string;
    transitAirport:string;
};

export type IPage = {
    limit: number;
    page: number;
    search: string;
}

type uiType = 'LIST' | 'EDIT' | 'ADD';

interface IPartnerUI {
    mode: uiType;
    isOpen: boolean;
}

interface IMasterdatas {
    loading: boolean;
    list: IMasterdataMaskapai[];
    error: string;
    ui: IPartnerUI;
    selected?: IMasterdataMaskapai;
}

const initialState: IMasterdatas = {
    loading: false,
    error: '',
    list : [],
    ui: {
        mode: 'LIST',
        isOpen: false
    },
}

export const fetchMasterData = createAsyncThunk('masterdatamaskapai/fetchList', async(payload:IPage,thunkAPI)=>{
    //if limit & page ==0 then display all data
    const url=(payload.limit==0 && payload.page==0)?REACT_APP_TARGET_SRV + '/airlines':
                (payload.search!=='')?REACT_APP_TARGET_SRV + '/airlines?name=' + payload.search: 
                  REACT_APP_TARGET_SRV + '/airlines?page=' + payload.page + '&limit=' + payload.limit;
        return axios
        .get(url, {headers: {...HttpService.header()}})
        .then(response=>{
           
            return response.data.map((v,i)=>{
                return {
                    ...v,
                    uid: v?.id,
                    id: i+1,
                    
                }
            })
        })
        .catch((err)=>{
            // console.log("--fromThunkAPI---",err);
            //Checking condition if error from API then display from API, else from Thunk
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.message;
            return thunkAPI.rejectWithValue(ErrCus);
         }) 
})

export const updateMasterData= createAsyncThunk('masterdatamaskapai/update',  async (payload:IMasterdataMaskapai,thunkAPI)=>{
    const fileList = []; ///declare filelist Upload

    return await axios
        .put(REACT_APP_TARGET_SRV+`/airlines/${payload?.uid}`,{...payload}, {headers: {...HttpService.header()}})
        .then(async response=>{
            return response.data;
        })
        .catch((err)=>{
            //console.log("--fromThunkAPI---",err);
            //Checking condition if error from API then display from API, else from Thunk
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.messages.join('\n');
            return thunkAPI.rejectWithValue(ErrCus);
        })
})

// interface IAddPayload {
//     categoryName: string;
//     agentFee: number;
//     referralFee: number;
// }
export const addMasterData = createAsyncThunk('masterdatamaskapai/add',  async (payload:IMasterdataMaskapai,thunkAPI)=>{
    return await axios
        .post(REACT_APP_TARGET_SRV+`/airlines`,{...payload}, {headers: {...HttpService.header()}})
        .then(async response=>{
            return response.data;
        })
        .catch((err)=>{
            //console.log("--fromThunkAPI---",err);
            //Checking condition if error from API then display from API, else from Thunk
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.messages.join('\n');
            return thunkAPI.rejectWithValue(ErrCus);
         })
})

export const MasterDataMaskapaiSlice = createSlice({
    name: 'MasterdataMaskapai',
    initialState,
    reducers: {
        setMasterdataList: (state, action)=>{
            state.list=action.payload;
        },
        setMasterdataUI: (state, action)=>{
            state.ui = {
                mode: action.payload.mode,
                isOpen: action.payload.isOpen
            };
        },
        setMasterdataSelected: (state, action)=>{

            const {MasterdataSelected, MasterdataList} = action.payload;
            let MasterdataSelectedData = {} as IMasterdataMaskapai;

            if (MasterdataSelected?.uid)
                MasterdataSelectedData = MasterdataList?.find(v=>
                v?.uid===MasterdataSelected?.uid
            );

            state.selected = {...MasterdataSelectedData }
        }
    },
    extraReducers: (builder) => {
        // list
        builder.addCase(fetchMasterData.pending, (state)=>{
            state.loading=true;
            state.error='';
            state.list=[]
        })
        builder.addCase(fetchMasterData.fulfilled, (state, action: PayloadAction<IMasterdataMaskapai[]>) => {
            state.loading=false;
            state.list=action.payload;
            state.error='';
        })
        builder.addCase(fetchMasterData.rejected, (state, action)=>{
            state.loading=false;
            state.list=[];
            state.error=action.error.message || 'Something went wrong';
        })
        // update
        builder.addCase(updateMasterData.pending, (state)=>{



            state.loading=true;
            state.error='';
        })
        builder.addCase(updateMasterData.fulfilled, (state, action)=>{
            state.loading=false;
            // const index = state.list.findIndex(val => val.id === action.payload.id);
            // state.list[index] = {
            //     ...state.list[index],
            //     ...action.payload
            // }
            state.error='';
        })
        builder.addCase(updateMasterData.rejected, (state, action)=>{
            state.loading=false;
            // state.list=state.list;
            state.error=action.error.message || 'Something went wrong';
        })
        // insert
        builder.addCase(addMasterData.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(addMasterData.fulfilled, (state, action)=>{
            state.loading=false;

            const nextOrderNumber:number = state.list.length+1;
            state.list.push({...action.payload.data, id:nextOrderNumber})
            state.error='';
        })
        builder.addCase(addMasterData.rejected, (state, action)=>{
            state.loading=false;
            // state.list=state.list;
            state.error=action.error.message || 'Something went wrong';
        })
    }
})

export const { setMasterdataList, setMasterdataSelected, setMasterdataUI } = MasterDataMaskapaiSlice.actions;
export default MasterDataMaskapaiSlice.reducer;
