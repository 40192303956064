import BrightnessLowTwoToneIcon from "@mui/icons-material/BrightnessLowTwoTone";
import DesignServicesTwoToneIcon from "@mui/icons-material/DesignServicesTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import TableChartTwoToneIcon from "@mui/icons-material/TableChartTwoTone";
import HowToVoteTwoToneIcon from "@mui/icons-material/HowToVoteTwoTone";
import { AccountBalanceWalletRounded, PaymentsRounded } from "@mui/icons-material";


export default [

  {
    groupLabel: 'Dashboards',
    items: [
      {
        activeLabel: 'Dashboard',
        url: '/dashboards/current-status',
        icon: BrightnessLowTwoToneIcon
      },
    ]
  },
  {
    groupLabel: 'Transaksi',
    items: [
      {
        activeLabel: 'Data Mitra',
        url: '/management/agents',
        icon: DesignServicesTwoToneIcon
      },
      {
        activeLabel: 'Data Jamaah',
        icon: AccountCircleTwoToneIcon,
        url: '/management/pilgrims'
      },
      {
        activeLabel: 'Data Paket',
        icon: TableChartTwoToneIcon,
        url: '/management/products'
      },
      {
        activeLabel: 'Data Kafalah',
        icon: PaymentsRounded,
        url: '/management/disbursement'
      }
    ],
  },
  /*  {
    groupLabel: 'Kafalah',
    items: [
      {
        activeLabel: 'Kafalah Mitra/Sub Mitra',
        icon: BeachAccessTwoToneIcon,
        url: '/management/kafalah-agents'
      },
      {
        activeLabel: 'Transaksi Kafalah',
        icon: TableChartTwoToneIcon,
        url: '/management/kafalah-trx'
      },
    ]
  }, 
  {
    groupLabel: 'Setting',
    items: [
      {
        activeLabel: 'Kategori Produk',
        icon: BallotTwoToneIcon,
        url: '/management/product-categories'
      },
    ]
  }, */
  {
    groupLabel: 'Logout',
    items: [{
      activeLabel: 'Logout',
      icon: HowToVoteTwoToneIcon,
      url: '/management/logout'
    }]
  }
];
