/* import Keycloak from "keycloak-js";
const {REACT_APP_HOMEPAGE_APP,REACT_APP_HOMEPAGE_KC,REACT_APP_KC_REALM,REACT_APP_KC_CLIENTID,REACT_APP_ROLE_SA} = process.env
 */

//const _kc = new Keycloak('/keycloak.txt');
/* const _kc = new Keycloak({
     realm: REACT_APP_KC_REALM,
     url: REACT_APP_HOMEPAGE_KC,
     clientId:REACT_APP_KC_CLIENTID,
     homepage:REACT_APP_HOMEPAGE_APP
});  */

/* const initKeycloak = (onAUthenticatedCallback) =>{
    _kc.init({
        onLoad: 'check-sso',
        // silentCheckSsoRedirectUri: Window?.location?.origin + '/silent-check-sso.html',
        pkceMethod: 'S256'
    })
        .then((authenticated) =>{
            if (authenticated){
                onAUthenticatedCallback();
            } else {
                console.warn("not authenticated");
                doLogin();
            }
        })
    } */
//const doLogin = _kc.login;
//const isLoggedIn = () => !!_kc.token;
const isLoggedIn=()=> !!localStorage.getItem("uniKey")
//const doLogout  = _kc.logout;
//const getToken = () => _kc.token;
const getToken=()=> localStorage.getItem("uniKey")

/* const updateToken = (successCallback)=>{
    _kc.updateToken(5)
        .then(successCallback)
        .catch(doLogin)
} */


/* const getUsername = () => _kc.tokenParsed?.preffered_username;
const getBranchIdUser = () => _kc.tokenParsed?.branch_id;
const getProfile = () => _kc.tokenParsed; */
//const hasRole = (roles) => roles.some(role=>_kc.hasRealmRole(role))
//const getUserRole = () => _kc.tokenParsed.realm_access.roles.slice(-1)


//const admin_acc_menu = ()=>(getUserRole().toString()===REACT_APP_ROLE_SA.toString())

const UserService = {
   // initKeycloak,
   // doLogin,
    //doLogout,
    isLoggedIn,
    getToken,
   // updateToken,
   // getUsername,
    //hasRole,
   // getProfile,
    //getUserRole,
   // getBranchIdUser,
    //admin_acc_menu
}
export default UserService;

