import {useEffect} from "react";
import UserService from "../../../services/UserService";
import { useNavigate } from 'react-router-dom';

function Logout() {
    const navigate = useNavigate();
    useEffect(() =>{
        navigate('/'); 
        navigate(0); 
        localStorage.clear()
        UserService.doLogout();
    },[])
    return (
        <></>
    )
}
export default Logout;
