
import { toast } from 'react-toastify';


const showToast = () => toast("Update in progress, please wait....", 
    {type: toast.TYPE.INFO,position: "top-center",autoClose: false,toastId:'toastX' });


const updateToast = (text,icon,timeAC?) => 
    {
        //console.log("timeAutoClose",timeAC)
        const typeToast = (icon==="SUCCESS")? toast.TYPE.SUCCESS:toast.TYPE.ERROR
        const timeAutoClose =  (timeAC==="undefined")?1000:timeAC
        toast.update('toastX', {render: text, type: typeToast, autoClose: timeAutoClose, position: "top-center" }) 
    }

 const closeToast =()=> toast.dismiss('toastX')   

 const newToast=(text,icon)=>{
    const typeToast = (icon==="SUCCESS")? toast.TYPE.SUCCESS:toast.TYPE.ERROR
    toast(text, {type: typeToast, autoClose: 3000, position: "top-center" }) 
}
 
const exportedObject = {
    showToast,updateToast,closeToast,newToast
};

export default exportedObject

