import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import BeachAccessTwoToneIcon from '@mui/icons-material/BeachAccessTwoTone';
import HowToVoteTwoToneIcon from '@mui/icons-material/HowToVoteTwoTone';

export default [
  {
    icon: DesignServicesTwoToneIcon
  },
  {
    icon: AccountCircleTwoToneIcon,
  },
  {
    icon: TableChartTwoToneIcon,
  },
  {
    icon: BeachAccessTwoToneIcon,
  },
  {
    icon: TableChartTwoToneIcon,
  },
  {
    icon: BallotTwoToneIcon,
  },
  {
    icon: TableChartTwoToneIcon,
  },
  {
    icon: TableChartTwoToneIcon,
  },
  {
    icon: BrightnessLowTwoToneIcon,
  },
  {
    icon: HowToVoteTwoToneIcon,
  },
]
