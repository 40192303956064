import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from "axios";
import HttpService from 'src/services/HttpService';
// import uploadFile from "../services/uploadFile";
const {REACT_APP_TARGET_SRV} = process.env

export type ContactPerson = {
    fullName: string;
    mobileNo: string;
};

export type IMasterdataHotel = {
  id: string;
  uid: string;
  name: string;
  description: string;
  starRating: string;
  availableRoomTypes: string[];
  streetAddress: string;
  city: string;
  stateOrProvince: string;
  country: string;
  phoneNumber: string;
  contactPersonList: ContactPerson[];
  pic1Name: string;
  pic1PhoneNo: string;
  pic2Name: string;
  pic2PhoneNo: string;
  rating: string;
}

export type IPage = {
    limit: number;
    page: number;
    search: string;
}

type uiType = 'LIST' | 'EDIT' | 'ADD';

interface IPartnerUI {
    mode: uiType;
    isOpen: boolean;
}

interface IMasterdatas {
    loading: boolean;
    list: IMasterdataHotel[];
    error: string;
    ui: IPartnerUI;
    selected?: IMasterdataHotel;
}

const initialState: IMasterdatas = {
    loading: false,
    error: '',
    list : [],
    ui: {
        mode: 'LIST',
        isOpen: false
    },
}

export const fetchMasterData = createAsyncThunk('masterdatahotel/fetchList', async(payload:IPage,thunkAPI)=>{
    const url=(payload.limit===0 && payload.page===0)?REACT_APP_TARGET_SRV + '/hotels':
                (payload.search!=='')?REACT_APP_TARGET_SRV + '/hotels?name=' + payload.search: 
                  REACT_APP_TARGET_SRV + '/hotels?page=' + payload.page + '&limit=' + payload.limit;
        return axios
        .get(url, {headers: {...HttpService.header()}})
        .then(response => response.data)
        .catch((err)=>{
            // console.log("--fromThunkAPI---",err);
            //Checking condition if error from API then display from API, else from Thunk
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.message;
            return thunkAPI.rejectWithValue(ErrCus);
         }) 
})

export const updateMasterData= createAsyncThunk('masterdatahotel/update',  async (payload:IMasterdataHotel,thunkAPI)=>{
    // const fileList = []; ///declare filelist Upload

    return await axios
        .put(REACT_APP_TARGET_SRV+`/hotels/${payload?.id}`,{...payload}, {headers: {...HttpService.header()}})
        .then(async response=>{
            return response.data;
        })
        .catch((err)=>{
            //console.log("--fromThunkAPI---",err);
            //Checking condition if error from API then display from API, else from Thunk
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.localMessage===null?err.message:err.response.data.localMessage;
            return thunkAPI.rejectWithValue(ErrCus);
        })
})

export const addMasterData = createAsyncThunk('masterdatahotel/add',  async (payload:IMasterdataHotel,thunkAPI)=>{
    return await axios
        .post(REACT_APP_TARGET_SRV+`/hotels`,{...payload}, {headers: {...HttpService.header()}})
        .then(async response=>{
            return response.data;
        })
        .catch((err)=>{
            //console.log("--fromThunkAPI---",err);
            //Checking condition if error from API then display from API, else from Thunk
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.messages.join('\n');
            return thunkAPI.rejectWithValue(ErrCus);
         })
})

export const MasterDataHotelSlice = createSlice({
    name: 'MasterdataHotel',
    initialState,
    reducers: {
        setMasterdataList: (state, action)=>{
            state.list=action.payload;
        },
        setMasterdataUI: (state, action)=>{
            state.ui = {
                mode: action.payload.mode,
                isOpen: action.payload.isOpen
            };
        },
        setMasterdataSelected: (state, action)=>{

            const {MasterdataSelected, MasterdataList} = action.payload;
            // let MasterdataSelectedData = {} as IMasterdataHotel;
            //
            // if (MasterdataSelected?.uid)
            //     MasterdataSelectedData = MasterdataList?.find(v=>
            //     v?.uid===MasterdataSelected?.uid
            // );

            const cpList = MasterdataSelected?.contactPersonList;

            state.selected = {
                ...MasterdataSelected,
                pic1Name: cpList && cpList.length > 0 ? cpList[0].fullName : '',
                pic1PhoneNo: cpList && cpList.length > 0 ? cpList[0].mobileNo : '',
                pic2Name: cpList && cpList.length > 1 ? cpList[1].fullName : '',
                pic2PhoneNo: cpList && cpList.length > 1 ? cpList[1].mobileNo : ''
            };
        }
    },
    extraReducers: (builder) => {
        // list
        builder.addCase(fetchMasterData.pending, (state)=>{
            state.loading=true;
            state.error='';
            state.list=[]
        })
        builder.addCase(fetchMasterData.fulfilled, (state, action: PayloadAction<IMasterdataHotel[]>) => {
            state.loading=false;
            state.list=action.payload;
            state.error='';
        })
        builder.addCase(fetchMasterData.rejected, (state, action)=>{
            state.loading=false;
            state.list=[];
            state.error=action.error.message || 'Something went wrong';
        })
        // update
        builder.addCase(updateMasterData.pending, (state)=>{



            state.loading=true;
            state.error='';
        })
        builder.addCase(updateMasterData.fulfilled, (state, action)=>{
            state.loading=false;
            // const index = state.list.findIndex(val => val.id === action.payload.id);
            // state.list[index] = {
            //     ...state.list[index],
            //     ...action.payload
            // }
            state.error='';
        })
        builder.addCase(updateMasterData.rejected, (state, action)=>{
            state.loading=false;
            // state.list=state.list;
            state.error=action.error.message || 'Something went wrong';
        })
        // insert
        builder.addCase(addMasterData.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(addMasterData.fulfilled, (state, action)=>{
            state.loading=false;

            const nextOrderNumber:number = state.list.length+1;
            state.list.push({...action.payload.data, id:nextOrderNumber})
            state.error='';
        })
        builder.addCase(addMasterData.rejected, (state, action)=>{
            state.loading=false;
            // state.list=state.list;
            state.error=action.error.message || 'Something went wrong';
        })
    }
})

export const { setMasterdataList, setMasterdataSelected, setMasterdataUI } = MasterDataHotelSlice.actions;
export default MasterDataHotelSlice.reducer;
