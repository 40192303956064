import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from "axios";
import HttpService from 'src/services/HttpService';
import { IProductCategory } from './ProductCategorySlice';
const {REACT_APP_TARGET_SRV} = process.env


export type IDataListTipe= {
    //make similar with API list (recommended)
    id: string;
    uid: string;
    typeName: string;
    productCategory: IProductCategory;
    categoryId:string;
    categoryName:string;
    active:boolean;

}

export type IPage = {
    limit?: number;
    page?: number;
}

type uiType = 'LIST' | 'EDIT' | 'ADD';

interface IUI {
    mode: uiType;
    isOpen: boolean;
}

interface IStateData {
    loading: boolean;
    list: IDataListTipe[];
    error: string;
    ui: IUI;
    selected?: IStateData;

}

const initialState: IStateData= {
    loading: false,
    error: '',
    list : [],
    ui: {
        mode: 'LIST',
        isOpen: false
    },
}

export const fetchProductTypes= createAsyncThunk('producttypes/fetchList', async(payload?:IPage)=>{
    const url=(payload.limit===0 && payload.page===0)?REACT_APP_TARGET_SRV + '/product_types?page=0&limit=150':
              REACT_APP_TARGET_SRV + '/product_types?page=' + payload.page + '&limit=' + payload.limit;

    return axios
       .get(url, {headers: {...HttpService.header()}})
       .then(response=>{
          const responseIn =  response.data.map((v,i)=>{
               return {
                   ...v,
                  uid: v.id,
                   id: i+1,
                   categoryId: {
                        uid: v.productCategory.id,
                        categoryName: v.productCategory.categoryName
                   },
                   categoryName: v.productCategory.categoryName
               }
           })

           return responseIn;
       })
})



export interface IAddPayload {
    typeName: string;
    categoryId: string;
}

export const addUser= createAsyncThunk('producttypes/add',  async (payload:IAddPayload, {rejectWithValue})=>{
    try {

        return await axios
            .post(REACT_APP_TARGET_SRV+`/product_types`,{...payload}, {headers: {...HttpService.header()}})
    }catch(e){        
        const ErrCus = (e.response.data===undefined || e.response.data===null) ? e.message:e.response.data.messages.join('\n');
        return rejectWithValue(ErrCus);
        // return rejectWithValue(e?.response?.data);
    }

});

interface IUpdatePayload {
    uid: string;
    typeName: string;
    categoryId: string;
}
export const updateUser= createAsyncThunk('producttypes/update',  async (payload:IUpdatePayload,thunkAPI)=>{
    return await axios
        .put(REACT_APP_TARGET_SRV+`/product_types/${payload?.uid}`,{...payload}, {headers: {...HttpService.header()}})
        .then(response=>{
            return response.data;
        })
        .catch((err)=>{
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.messages.join('\n');
            return thunkAPI.rejectWithValue(ErrCus);
         })
})



export const dataSlice = createSlice({
    name: 'product_type',
    initialState,
    reducers: {
        setUserList: (state, action)=>{
            state.list=action.payload;
        },
        setUI: (state, action)=>{
            state.ui = {
                mode: action.payload.mode,
                isOpen: action.payload.isOpen
            };
        },
        setProductTypeSelected: (state, action)=>{

             let realPayload = {...action.payload?.userSelected}
 
            state.selected = realPayload;
        }
    },
    extraReducers: (builder) => {
        // list
        builder.addCase(fetchProductTypes.pending, (state)=>{
            state.loading=true;
            state.error='';
            state.list=[]
        })
        builder.addCase(fetchProductTypes.fulfilled, (state, action: PayloadAction<IDataListTipe[]>) => {
            state.loading=false;
            state.list=action.payload;
            state.error='';
        })
        builder.addCase(fetchProductTypes.rejected, (state, action)=>{
            state.loading=false;
            state.list=[];
            state.error=action.error.message || 'Something went wrong';
        })
        // insert
        builder.addCase(addUser.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(addUser.fulfilled, (state, action)=>{

            state.loading=false;
            state.error='';
        })
        builder.addCase(addUser.rejected, (state, action)=>{

            state.loading=false;
            state.list=[];
            state.error= action?.payload ? action?.payload['message'] : 'Something went wrong';
        })
        // update
        builder.addCase(updateUser.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(updateUser.fulfilled, (state, action)=>{
            state.loading=false;
            // const index = state.list.findIndex(val => val.id === action.payload.id);
            // state.list[index] = {
            //     ...state.list[index],
            //     ...action.payload
            // }
            state.error='';
        })
        builder.addCase(updateUser.rejected, (state, action)=>{
            state.loading=false;
            // state.list=state.list;
            state.error=action.error.message || 'Something went wrong';
        })
    }
})


export const { setUserList, setProductTypeSelected, setUI } = dataSlice.actions;
export default dataSlice.reducer;


