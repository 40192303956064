import axios from "axios";
import UserService from "src/services/UserService";

const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
};

const _axios = axios.create();
const header = (v)=>{
  const headerData = {Authorization: `Bearer ${UserService.getToken()}`}
  return headerData;
}

const configure = () => {
  if (UserService.isLoggedIn()){
    // UserService.updateToken(header);
  }
};

/* const roles = () =>{
  const profile = UserService.getProfile()
  return profile?.realm_access?.roles;
} */

const getAxiosClient = () => _axios;

const HttpService = {
  HttpMethods,
  configure,
  getAxiosClient,
  header,
 // roles
};

export default HttpService;
