import {configureStore} from '@reduxjs/toolkit';
import prodCategorySlice from "./ProductCategorySlice";
import productSlice from "./ProductSlice";
import branchSlice from "./BranchSlice";
import roleSlice from "./Role";
import authSlice from "./Auth";
import customerSlice from "./CustomerSlice";
import partnerSlice from "./PartnerSlice";
import disbursementSlice from "./DisbursementSlice";
import menuSlice from './MenuSlice';
import Userzamzam from './Userzamzam';
import ProductType from './ProductTypeSlice';
import Dashboard from './Dashboard';
import masterdataHotelSlice from './MasterdataHotelSlice';
import masterdataMaskapaiSlice from './MasterdataMaskapaiSlice';
import masterdataVisaProviderSlice from './MasterdataVisaProviderSlice';
import masterdataInsuranceSlice from './MasterdataInsuranceSlice';



const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        productCategory: prodCategorySlice,
        product: productSlice,
        branch: branchSlice,
        role: roleSlice,
        customer: customerSlice,
        partner: partnerSlice,
        disbursement: disbursementSlice,
        menu: menuSlice,
        userzamzam: Userzamzam,
        productType: ProductType,
        masterDataHotel:masterdataHotelSlice,
        masterDataMaskapai:masterdataMaskapaiSlice,
        masterDataVisaProvider:masterdataVisaProviderSlice,
        masterdataInsurance:masterdataInsuranceSlice,
        dashboard:Dashboard
    }
})
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// export const selectAuth = (state: RootState) => state.auth;
// export const selectProductCategory = (state: RootState) => state.productCategory;
// export const fetchProductCategory = () => useDispatch<AppDispatch>();
export default store;
