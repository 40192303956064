import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from "axios";
import HttpService from 'src/services/HttpService';

import uploadFile from "src/services/uploadFile"
const {REACT_APP_TARGET_SRV} = process.env

export interface IContactPerson {
    fullName: string;
    mobileNo: string;
    // wa: string;
}
export interface ISocialMediaAccount {
    accountName: string;
    accountType: string;
}

export interface IBranch {
    id: string;
    uid: string;
    name: string; //
    streetAddress: string; //
    city: string; //
    province: string; //
    district: string;
    subDistrict: string;
    phoneNo: string;
    contactPersonList: IContactPerson[];
    active: boolean;
    postalCode: string;
    bankName: string;   //
    bankAccountNo: string; //
    bankAccountName: string; //
    bankAccountBranch: string;
    hqRepresentative: boolean;
    branchType: string;

    aktaNo: string;
    aktaFile:string;
    aktaFileOri: File;
    aktaMimeType: string;
    aktaFileSize: number;

    nib: string;
    nibFile :string;
    nibFileOri: File;
    nibMimeType: string;
    nibFileSize: number;

    npwp: string;
    npwpFile :string;
    npwpFileOri: File;
    npwpMimeType: string;
    npwpFileSize: number;

    skduNo: string;
    skduFile :string;
    skduFileOri: File;
    skduMimeType: string;
    skduFileSize: number;

    kemenagSkNo: string;
    kemenagSkFile :string;
    kemenagSkFileOri: File;
    kemenagSkMimeType: string;
    kemenagSkFileSize: number;

    skNo: string;
    skFile :string;
    skFileOri: File;
    skMimeType: string;
    skFileSize: number;

    akadNo: string;
    akadFile :string;
    akadFileOri: File;
    akadMimeType: string;
    akadFileSize: number;

    socialMediaAccounts: ISocialMediaAccount[];
    websiteUrl: string;
    kafalahAllocationFee: number;
    infantKafalahAllocationFee: number;
    contactPersonFullName1: string;
    contactPersonMobileNo1: string;
    contactPersonFullName2: string;
    contactPersonMobileNo2: string;
    contactPersonFullName3: string;
    contactPersonMobileNo3: string;
    contactPersonFullName4: string;
    contactPersonMobileNo4: string;
    facebookAcc: string;
    instagramAcc: string;
    email: string;
}


export type IPage = {
    limit: number;
    page: number;
    search: string;
}


type uiType = 'LIST' | 'EDIT' | 'ADD';

interface IBranchUI {
    mode: uiType;
    isOpen: boolean;
}

interface IBranches {
    loading: boolean;
    list: IBranch[];
    uploadLink: string;
    error: string;
    ui: IBranchUI;
    selected?: IBranch;

}

const initialState: IBranches = {
    loading: false,
    error: '',
    list : [],
    uploadLink: '',
    ui: {
        mode: 'LIST',
        isOpen: false
    },
}

export const fetchBranches = createAsyncThunk('branch/fetchList', async(payload:IPage)=>{
    //if limit & page ==0 then display all data
    const url=(payload.limit==0 && payload.page==0)?REACT_APP_TARGET_SRV + '/branches':
                (payload.search!=='')?REACT_APP_TARGET_SRV + '/branches?name=' + payload.search: 
                  REACT_APP_TARGET_SRV + '/branches?page=' + payload.page + '&limit=' + payload.limit;
        return axios
        .get(url, {headers: {...HttpService.header()}})
        .then(response=>{
            let contactPersonFullName1:string='';
            let contactPersonMobileNo1:string='';
            let contactPersonFullName2:string='';
            let contactPersonMobileNo2:string='';
            let contactPersonFullName3:string='';
            let contactPersonMobileNo3:string='';
            let contactPersonFullName4:string='';
            let contactPersonMobileNo4:string='';
            let facebookAcc:string='';
            let instagramAcc:string='';

            return response.data.map((v,i)=>{
                if (v?.contactPersonList && typeof v?.contactPersonList == 'object'){
                    v?.contactPersonList.forEach((val, i)=>{
                        if (i===0){
                            contactPersonFullName1=val?.fullName;
                            contactPersonMobileNo1=val?.mobileNo;
                        } else if (i===1) {
                            contactPersonFullName2=val?.fullName;
                            contactPersonMobileNo2=val?.mobileNo;
                        } else if (i===2) {
                            contactPersonFullName3=val?.fullName;
                            contactPersonMobileNo3=val?.mobileNo;
                        } else if (i===3) {
                            contactPersonFullName4=val?.fullName;
                            contactPersonMobileNo4=val?.mobileNo;
                        }
                    })
                }
                if (v?.socialMediaAccounts && typeof v?.socialMediaAccounts == 'object'){
                    v?.socialMediaAccounts.forEach((val, i)=>{
                        (val?.accountType==='Instagram')? 
                        instagramAcc=val?.accountName:
                        facebookAcc=val?.accountName
                    })
                }
                return {
                    ...v,
                    id: i+1,
                    uid: v?.id,
                    cpName: v?.contactPersonName,
                    cpMobileNo: v?.contactPersonMobileNo,
                    bankAccountBranch: v?.bankAccountOfficeBranch,
                    contactPersonFullName1: contactPersonFullName1,
                    contactPersonMobileNo1: contactPersonMobileNo1,
                    contactPersonFullName2: contactPersonFullName2,
                    contactPersonMobileNo2: contactPersonMobileNo2,
                    contactPersonFullName3: contactPersonFullName3,
                    contactPersonMobileNo3: contactPersonMobileNo3,
                    contactPersonFullName4: contactPersonFullName4,
                    contactPersonMobileNo4: contactPersonMobileNo4,
                    facebookAcc: facebookAcc,
                    instagramAcc: instagramAcc,
                    aktaFileName:v?.aktaFile,
                    nibFileName:v?.nibFile,
                    npwpFileName:v?.npwpFile,
                    skduFileName:v?.skduFile,
                    kemenagSkFileName:v?.kemenagSKFile,
                    skFile:v?.skFile,
                    akadFile:v?.akadFile
                }
            })
        })
})


export const updateBranch = createAsyncThunk('branch/update',  async (payload:IBranch,thunkAPI)=>{
    let contactPersons = [];
    let socialMediaAccountsLists = [];
    for (let i=0;i<4; i++ ){
        const contactPerson:IContactPerson = {
            fullName: payload[`contactPersonFullName${i+1}`] || '',
            mobileNo: payload[`contactPersonMobileNo${i+1}`] || '',
            // wa: payload[`contactPersonMobileNo${i}`]
        }


        contactPersons.push(contactPerson)
    }

    if (payload[`facebookAcc`] !==""){
        const socialMediaAccountsList:ISocialMediaAccount={
            accountName: payload[`facebookAcc`] || '',
            accountType:'Facebook' || ''
        }
        socialMediaAccountsLists.push(socialMediaAccountsList)
    }

    if (payload[`instagramAcc`] !==""){
        const socialMediaAccountsList:ISocialMediaAccount={
            accountName: payload[`instagramAcc`] || '',
            accountType:'Instagram' || ''
        }
        socialMediaAccountsLists.push(socialMediaAccountsList)
    }

    const theRealPayload = {
        ...payload,
        contactPersonList: contactPersons,
        socialMediaAccounts: socialMediaAccountsLists
    }

    const fileList = []; ///declare filelist Upload

    //set constant for upload file name
    if(theRealPayload.aktaFile){
        fileList.push("aktaFileOri");
    }
    if(theRealPayload.nibFile){
        fileList.push("nibFileOri")
        theRealPayload.nibFile='nibFile';
    }
    if(theRealPayload.npwpFile){
        fileList.push("npwpFileOri")
    }
    if(theRealPayload.skduFile){
        fileList.push("skduFileOri")
    }
    if(theRealPayload.kemenagSkFile){
        fileList.push("kemenagSkFileOri")
    }
    if(theRealPayload.skFile){
        fileList.push("skFileOri")
    }
    if(theRealPayload.akadFile){
        fileList.push("akadFileOri")
    }
   //const fileList = ["aktaFile","nibFile","npwpFile","skduFile","kemenagSkFile"]

   //change the filename based on filename and that ext
    for (let i in fileList) {
        let fileNameAndExt = theRealPayload[`${fileList[i]}`]?.name.split('.')
        fileNameAndExt = fileList[i] +'.' + fileNameAndExt[fileNameAndExt.length-1];
        fileNameAndExt = fileNameAndExt.replace("Ori","")

        theRealPayload[fileList[i].replace("Ori","")]=fileNameAndExt         
   }

    
        return await axios
        .put(REACT_APP_TARGET_SRV+`/branches/${payload?.uid}`,{...theRealPayload}, {headers: {...HttpService.header()}})
        .then(async response=>{
            await uploadFile(theRealPayload, fileList,"branches")           
            return response.data;
        })
        .catch((err)=>{
            // console.log("--fromThunkAPI---",err);
            //Checking condition if error from API then display from API, else from Thunk
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.messages.join('\n');
            return thunkAPI.rejectWithValue(ErrCus);
        })     
        
})

export const addBranch = createAsyncThunk('branch/add',  async (payload:IBranch,thunkAPI)=>{

    let contactPersons = [];
    let socialMediaAccountsLists = [];

    for (let i=0;i<2;i++ ){
        const contactPerson:IContactPerson = {
            fullName: payload[`contactPersonFullName${i+1}`] || '',
            mobileNo: payload[`contactPersonMobileNo${i+1}`] || '',

        }
        contactPersons.push(contactPerson)
    }

   
    if (payload[`facebookAcc`] !==""){
        const socialMediaAccountsList:ISocialMediaAccount={
            accountName: payload[`facebookAcc`] || '',
            accountType:'Facebook' || ''
        }
        socialMediaAccountsLists.push(socialMediaAccountsList)
    }

    if (payload[`instagramAcc`] !==""){
        const socialMediaAccountsList:ISocialMediaAccount={
            accountName: payload[`instagramAcc`] || '',
            accountType:'Instagram' || ''
        }
        socialMediaAccountsLists.push(socialMediaAccountsList)
    }


    let theRealPayload = {
        ...payload,
        contactPersonList: contactPersons,
        socialMediaAccounts: socialMediaAccountsLists
    }
    //const fileList = ["aktaFile","nibFile","npwpFile","skduFile","kemenagSkFile"]

    return await axios
        .post(REACT_APP_TARGET_SRV+`/branches`,{...theRealPayload}, {headers: {...HttpService.header()}})
        .then(async response=>{
            return response.data;
        })
        .catch((err)=>{
            // console.log("--fromThunkAPI---",err);
            //Checking condition if error from API then display from API, else from Thunk
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.messages.join('\n');
            return thunkAPI.rejectWithValue(ErrCus);
        })     
})

export const branchSlice = createSlice({
    name: 'Branch',
    initialState,
    reducers: {
        setBranchList: (state, action)=>{
            state.list=action.payload;
        },
        setBranchUI: (state, action)=>{
            state.ui = {
                mode: action.payload.mode,
                isOpen: action.payload.isOpen
            };
        },
        setBranchSelected: (state, action)=>{

            const {branchSelected, branchList} = action.payload;
            let branchSelectedData =  <IBranch>{}
            if (branchSelected?.uid) branchSelectedData = branchList?.find(v=>v?.uid===branchSelected?.uid);

            //, uid: branchSelectedData?.branchKey?.uid, hqRepresentative: branchSelectedData?.branchKey?.hqRepresentative
            state.selected = {...branchSelectedData }
        }
    },
    extraReducers: (builder) => {
        // list

        builder.addCase(fetchBranches.pending, (state)=>{
            state.loading=true;
            state.error='';
            state.list=[]
        })
        builder.addCase(fetchBranches.fulfilled, (state, action: PayloadAction<IBranch[]>) => {
            state.loading=false;
            state.list=action.payload;
            state.error='';
        })
        builder.addCase(fetchBranches.rejected, (state, action)=>{
            state.loading=false;
            state.list=[];
            state.error=action.error.message || 'Something went wrong';
        })

/*
        // generate upload link
        builder.addCase(generateUploadLink.pending, (state)=>{
            state.loading=true;
            state.error='';
            state.list=[];
            state.uploadLink='';
        })
        builder.addCase(generateUploadLink.fulfilled, (state, action: PayloadAction<IBranch[]>) => {
            console.log('----action uploadlink----', action)
            state.loading=false;
            state.list=action.payload;
            state.error='';
            state.uploadLink='';
        })
        builder.addCase(generateUploadLink.rejected, (state, action)=>{
            state.loading=false;
            state.list=[];
            state.error=action.error.message || 'Something went wrong';
            state.uploadLink='';
        })
*/
        // update
        builder.addCase(updateBranch.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(updateBranch.fulfilled, (state, action)=>{
            // const dispatch = useAppDispatch();
            state.loading=false;
            // dispatch(fetchBranches());
            // const index = state.list.findIndex(val => val.id === action.payload.id);
            // state.list[index] = {
            //     ...state.list[index],
            //     ...action.payload
            // }
            state.error='';
        })
        builder.addCase(updateBranch.rejected, (state, action)=>{      
            state.loading=false;
            state.error=action.error.message || 'Something went wrong';
        })
        // insert
        builder.addCase(addBranch.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(addBranch.fulfilled, (state, action: PayloadAction<{}| IBranches>)=>{
            state.loading=false;
            // const nextOrderNumber:string = `${state.list.length+1}`;

            // state.list.push({...action.payload["data"], id: nextOrderNumber})
            state.error='';
        })
        builder.addCase(addBranch.rejected, (state, action)=>{
            state.loading=false;
            // state.list=state.list;
            state.error=action.error.message || 'Something went wrong';
        })
    }
})

export const { setBranchList, setBranchSelected, setBranchUI } = branchSlice.actions;
export default branchSlice.reducer;
