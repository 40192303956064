import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from "axios";
import HttpService from 'src/services/HttpService';
const {REACT_APP_TARGET_SRV} = process.env


interface IDatarole {
    id: string;
    name: string;
}

export type IDataList= {
    //make similar with API list (recommended)
    id: string;
    uid:string;
    username: string;
    name: string;
    email: string;
    branchId: string;
    userId:string;
    createdTimestamp: string;
    enabled:boolean;

    firstName: string;
    lastName: string;
    role:[IDatarole];
}

type uiType = 'LIST' | 'EDIT' | 'ADD';

interface IUI {
    mode: uiType;
    isOpen: boolean;
}

interface IStateData {
    loading: boolean;
    list: IDataList[];
    error: string;
    ui: IUI;
    selected?: IStateData;

}

const initialState: IStateData= {
    loading: false,
    error: '',
    list : [],
    ui: {
        mode: 'LIST',
        isOpen: false
    },
}

export const fetchRoleList= createAsyncThunk('role/fetchList', ()=>{
    return axios
       //.get(`/api/users/roles`, {headers: {...HttpService.header()}})
       .get(REACT_APP_TARGET_SRV + `/users/roles`, {headers: {...HttpService.header()}})
       .then(response=>{

           return response.data.filter(role => role.name !== "ROLE_PARTNER").map((v,i)=>{
               return {
                   ...v,
                   uid: v?.id,
                   id: i+1,
               }
           })
       })
})

interface IAddPayload {
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    password: string;
    verifyPassword: string;
    branchId: string;
    roles:[{
        id: string;
        name: string;
    }]
    enabled: boolean;
}

export const addRole= createAsyncThunk('role/add',  async (payload:IAddPayload)=>{
    return await axios
       // .post(`/api/users/role`,{...payload}, {headers: {...HttpService.header()}})
        .post(REACT_APP_TARGET_SRV + `/users/role`,{...payload}, {headers: {...HttpService.header()}})
        .then(response=>{
            return response.data;
        })
})

interface IUpdatePayload {
    uid: string;
    categoryName: string;
    referralFee: number;
    agentFee: number;
}
export const updateRole= createAsyncThunk('role/update',  async (payload:IUpdatePayload)=>{

    return await axios
        //.put(`/api/users/roles/${payload?.uid}`,{...payload}, {headers: {...HttpService.header()}})
        .put(REACT_APP_TARGET_SRV + `/users/roles/${payload?.uid}`,{...payload}, {headers: {...HttpService.header()}})
        .then(response=>{
            return response.data;
        })
})



export const dataSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        setRoleList: (state, action)=>{
            state.list=action.payload;
        },
        setUI: (state, action)=>{
            state.ui = {
                mode: action.payload.mode,
                isOpen: action.payload.isOpen
            };
        },
        setRoleSelected: (state, action)=>{

            state.selected = action.payload;
        }
    },
    extraReducers: (builder) => {
        // list
        builder.addCase(fetchRoleList.pending, (state)=>{
            state.loading=true;
            state.error='';
            state.list=[]
        })
        builder.addCase(fetchRoleList.fulfilled, (state, action: PayloadAction<IDataList[]>) => {
            state.loading=false;
            state.list=action.payload;
            state.error='';
        })
        builder.addCase(fetchRoleList.rejected, (state, action)=>{
            state.loading=false;
            state.list=[];
            state.error=action.error.message || 'Something went wrong';
        })
        // insert
        builder.addCase(addRole.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(addRole.fulfilled, (state, action)=>{
            state.loading=false;
            const nextOrderNumber:number = state.list.length+1;
            state.list.push({...action.payload.data, id:nextOrderNumber})
            state.error='';
        })
        builder.addCase(addRole.rejected, (state, action)=>{
            state.loading=false;
            state.list=[];
            state.error=action.error.message || 'Something went wrong';
        })
        // update
        builder.addCase(updateRole.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(updateRole.fulfilled, (state, action)=>{
            state.loading=false;
            const index = state.list.findIndex(val => val.id === action.payload.id);
            state.list[index] = {
                ...state.list[index],
                ...action.payload
            }
            state.error='';
        })
        builder.addCase(updateRole.rejected, (state, action)=>{
            state.loading=false;
            // state.list=state.list;
            state.error=action.error.message || 'Something went wrong';
        })
    }
})


export const { setRoleList, setRoleSelected, setUI } = dataSlice.actions;
export default dataSlice.reducer;
