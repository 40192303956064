import { IMasterdataMaskapai } from './MasterdataMaskapaiSlice';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from "axios";
import HttpService from 'src/services/HttpService';
import {ProductStatus} from 'src/constants/ProductStatus';
import uploadFile from "src/services/uploadFile"
import { IMasterdataHotel } from './MasterdataHotelSlice';
import { IDataListTipe } from './ProductTypeSlice';
import { AdditionalSchedule, Airline, Hotel, Transport } from 'src/content/applications/Products/Crud/types';
const {REACT_APP_TARGET_SRV} = process.env

export interface IProductType {
    id: string;
    typeName: string;
}

export type IProduct = {
    id?: string;
    uid?: string;
    productName: string;
    productType:IProductType;
    typeId: IDataListTipe;
    typeName: string;
    programId: string;
    programName: string;
    airlines: IMasterdataMaskapai[];
    hotels: IMasterdataHotel[];
    lengthOfJourney?: string;
    departureDate: Date;
    arrivalDate: Date;
    preIssuedPilgrims: number;
    issuedPilgrims: number;
    totalPilgrims: number;
    status: ProductStatus;
    price: number;
    downPayment: number;
    currency: string;

    brochureFile: string;
    brochureFileOri: File;
    brochureFileType: string;
    brochureFileSize: number;

    itineraryFile: string;
    itineraryFileOri: File;
    itineraryFileType: string;
    itineraryFileSize: number;

    createdBy: string;
    createdAt: Date;
    updatedBy: string;
    advisor: string;
    advisorMobileNo: string;
    teamLeader: string;
    teamLeaderMobileNo: string;
    bookingCode: string;
    muthawwif:string;
    muthawwifMobileNo:string;
    buses: string[];
    pax:number;
    issued:number;
    pnr:string;
    privateGroup:boolean;
    updatedAt: Date;
    branchID: string;

    kafalahFeeEnabled?:boolean;
    kafalahAllocationFee: number;
    agentPercentageFee: number;
    referralPercentageFee: number;
    branchPercentageFee: number;
    agentFee?: number,
    referralFee?: number,
    branchFee?: number,

    infantKafalahFeeEnabled?: boolean;
    infantKafalahAllocationFee?: number;
    infantAgentPercentageFee?: number;
    infantReferralPercentageFee?: number;
    infantBranchPercentageFee?: number;
    infantAgentFee?: number;
    infantReferralFee?: number;
    infantBranchFee?: number;

    branchName: string;
    kafalahAllocationFeeBranch: number;
    kafalahAllocationFeeProductCategory: number;
}

export type IPage = {
    limit: number;
    page: number;
    search: string;
}

type uiType = 'LIST' | 'EDIT' | 'ADD';

interface IProductUI {
    mode: uiType;
    isOpen: boolean;
}

interface IProducts {
    loading: boolean;
    list: IProduct[];
    error: string;
    ui: IProductUI;
    selected?: IProduct;

}

const initialState: IProducts = {
    loading: false,
    error: '',
    list : [],
    ui: {
        mode: 'LIST',
        isOpen: false
    },
}

interface IUpdateProductStatus {
    uid: string;
    status: string;
}

export const fetchProducts = createAsyncThunk('product/fetchList', async(payload:IPage,thunkAPI)=>{
     const url=(payload.limit===0 && payload.page===0)?REACT_APP_TARGET_SRV + '/products':
            (payload.search!=='')?REACT_APP_TARGET_SRV + '/products?name=' + payload.search: 
              REACT_APP_TARGET_SRV + '/products?page=' + payload.page + '&limit=' + payload.limit;
    return axios
        .get(url, {headers: {...HttpService.header()}})
        .then(response=>{
            return response?.data?.map((v,i)=>{
                return {
                    ...v,
                    uid: v?.id,
                    id: i+1,
                    typeId: {
                        uid: v.productType.id,
                        typeName: v.productType.typeName
                   },
                   typeName: v.productType.typeName,
                   programId: {
                        uid: v.program?.id,
                        programName: v.program?.programName
                   },
                   programName: v.program?.programName,
                   itineraryFile: v?.itineraryFileName,
                   brochureFile:v?.brochureFileName,
                   branchName:v?.branch.name,
                   kafalahAllocationFeeBranch:v?.kafalahAllocationFee,
                   kafalahAllocationFeeProductCategory:v?.kafalahAllocationFee,
                }
            })
        })
        .catch((err)=>{
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.message;
            return thunkAPI.rejectWithValue(ErrCus);
         }) 
})

export const updateProduct = createAsyncThunk('product/update',  async (payload: EditProductPayload, thunkAPI)=>{
    const uid = payload.uid;
    const fileList = []; ///declare filelist Upload

    //set constant for upload file name
    if(payload.itineraryFile){
        //payload.itineraryFileName="itineraryFile"
        fileList.push("itineraryFileOri")
    }
    if(payload.brochureFile){
        //payload.brochureFileName="brochureFile"
        fileList.push("brochureFileOri")
    }
    
  //change the filename based on filename and that ext
    for (let i in fileList) {
        let fileNameAndExt = payload[`${fileList[i]}`]?.name.split('.')
        fileNameAndExt = fileList[i] +'.' + fileNameAndExt[fileNameAndExt.length-1];
        fileNameAndExt = fileNameAndExt.replace("Ori","")

        payload[fileList[i].replace("Ori","")]=fileNameAndExt         
    }

      return await axios
        .put(REACT_APP_TARGET_SRV+`/products/${uid}`, payload, {headers: {...HttpService.header()}})
        .then(async response=>{
            await uploadFile(payload, fileList,"products")
            return response.data;
        })  
        .catch((err)=>{
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.messages.join('\n');
            return thunkAPI.rejectWithValue(ErrCus);
         })
})

export const updateProductStatus = createAsyncThunk('product/updateStatus', async (payload: IUpdateProductStatus, thunkAPI) => {
    return await axios
        .patch(REACT_APP_TARGET_SRV + '/products/' + payload.uid + '?status=' + payload.status, null, {headers: {...HttpService.header()}})
        .then(async response => {
            return response.data;
        })
        .catch((err) => {
            console.log('error=', err);
            // const ErrCus = (err.response.data === undefined || err.response.data === null) ? err.message : err.response.data.messages.join('\n');
            // return thunkAPI.rejectWithValue(ErrCus);
        })
})

export type CreateProductPayload = {
  uid?: string
  productName: string
  typeId: string
  programId?: string
  advisor: string
  advisorMobileNo: string
  teamLeader: string
  teamLeaderMobileNo: string
  muthawwif: string
  muthawwifMobileNo: string
  airlines?: (Airline & {
    boardingDateTime: string
    arrivalDateTime: string
  })[]
  hotels?: (Hotel & {
    checkIn: string
    checkOut: string
  })[]
  detailTransports?: (Transport & {
    dateTime: string
  })[]
  additionalSchedules?: (AdditionalSchedule & {
    date: string
  })[]
  buses?: string[]
  lengthOfJourney?: string
  departureDate: string
  arrivalDate: string
  pax: number
  pnr: string
  productStatus?: string
  privateGroup: boolean
  price: number
  downPayment: number

  agentPercentageFee?: number
  referralPercentageFee?: number
  branchPercentageFee?: number
  kafalahAllocationFee?: number
  kafalahFeeEnabled: boolean

  infantAgentPercentageFee?: number
  infantReferralPercentageFee?: number
  infantBranchPercentageFee?: number
  infantKafalahAllocationFee?: number
  infantKafalahFeeEnabled: boolean

  currency: string
}

export type EditProductPayload = CreateProductPayload & {
  productTypeId: string
 
  brochureFile?: string
  brochureFileOri?: File
  brochureFileType?: string
  brochureFileSize?: number

  itineraryFile?: string
  itineraryFileOri?: File
  itineraryFileType?: string
  itineraryFileSize?: number
}

export const addProduct = createAsyncThunk('product/add',  async (payload: CreateProductPayload, thunkAPI)=>{   
    return await axios
        .post(REACT_APP_TARGET_SRV+`/products`, payload, {headers: {...HttpService.header()}})
        .then(async response=>{
            return response.data;
        })
        .catch((err)=>{
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.messages.join('\n');
            return thunkAPI.rejectWithValue(ErrCus);
        })
})

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setProductList: (state, action)=>{
            state.list=action.payload;
        },
        setProductUI: (state, action)=>{
            state.ui = {
                mode: action.payload.mode,
                isOpen: action.payload.isOpen
            };
        },
        setProductSelected: (state, action)=>{
            const {productSelected, productList} = action.payload;
            let productSelectedData = {} as IProduct;
            if (productSelected?.uid) {
                productSelectedData = productList?.find(v=>v?.uid===productSelected?.uid);

                const productSelectedHotels = productSelectedData?.hotels;    
                const productSelectedAirlines = productSelectedData?.airlines;
                                
                state.selected = {
                    ...productSelectedData,
                    hotels: productSelectedHotels ? productSelectedHotels : [],
                    airlines: productSelectedAirlines ? productSelectedAirlines : [],
                    // selectedHotels: productSelectedHotels ? productSelectedHotels : [],
                    // selectedHotels: [],
                    // selectedAirlines: productSelectedAirlines ? productSelectedAirlines : []
                };
            }else{
                state.selected = {} as IProduct;
            } 
        }
    },
    extraReducers: (builder) => {
        // list
        builder.addCase(fetchProducts.pending, (state)=>{
            state.loading=true;
            state.error='';
            state.list=[]
        })
        builder.addCase(fetchProducts.fulfilled, (state, action: PayloadAction<IProduct[]>) => {
            state.loading=false;
            state.list=action.payload;
            state.error='';
        })
        builder.addCase(fetchProducts.rejected, (state, action)=>{
            state.loading=false;
            state.list=[];
            state.error=action.error.message || 'Something went wrong';
        })
        // update
        builder.addCase(updateProduct.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(updateProduct.fulfilled, (state, action)=>{
            state.loading=false;
            state.error='';
        })
        builder.addCase(updateProduct.rejected, (state, action)=>{
            state.loading=false;
            state.error=action.error.message || 'Something went wrong';
        })
        // insert
        builder.addCase(addProduct.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(addProduct.fulfilled, (state, action)=>{
            state.loading=false;
            const nextOrderNumber:number = state.list.length+1;
            state.list.push({...action.payload, id:nextOrderNumber})
            state.error='';
        })
        builder.addCase(addProduct.rejected, (state, action)=>{
            state.loading=false;
            state.error=action.error.message || 'Something went wrong';
        })
    }
})

export const { setProductList, setProductSelected, setProductUI } = productSlice.actions;
export default productSlice.reducer;
