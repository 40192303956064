import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from "axios";
import HttpService from 'src/services/HttpService';
import uploadFile from "../services/uploadFile";
const {REACT_APP_TARGET_SRV} = process.env


export type IDisbursement = {
  id: string;
  uid: string;
  closingDate: Date;
  productId: string;
  productName: string;
  customerName: string;
  branch: string;
  productPrice: number;
  productKafalah: number;
  kafalahPercentage: number;
  receivedAmount: number;
  description: string;
  status: string;
  note?: string;
  kafalahType: string;
}

export type IPage = {
    limit: number;
    page: number;
    search: string;
}

type uiType = 'LIST' | 'EDIT' | 'ADD';

interface IDisbursementUI {
    mode: uiType;
    isOpen: boolean;
}

interface IDisbursements {
    loading: boolean;
    list: IDisbursement[];
    error: string;
    ui: IDisbursementUI;
    selected?: IDisbursement;
    disbursementPaymentHistory?: any;
}

const initialState: IDisbursements = {
    loading: false,
    error: '',
    list : [],
    ui: {
        mode: 'LIST',
        isOpen: false
    },
    disbursementPaymentHistory:[]
}

export const fecthDisbursement = createAsyncThunk('Disbursement/fetchList', async(payload:IPage,thunkAPI)=>{
    const url = (payload.limit===0 && payload.page===0)? `${REACT_APP_TARGET_SRV}/kafalah/reports` : 
            `${REACT_APP_TARGET_SRV}/kafalah/reports?page=${payload.page}&limit=${payload.limit}`;

        return axios
        .get(url, {headers: {...HttpService.header()}})
        .then(response=>{
           
            return response.data.map((v,i)=>{
                return {
                    ...v,
                    uid: v?.id,
                    id: i+1
                }
            })
        })
        .catch((err)=>{
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.message;
            return thunkAPI.rejectWithValue(ErrCus);
         }) 
})

interface IUpdatePayload {
    requestId:string;
    action: string;
 }
export const updateDisbursement = createAsyncThunk('Disbursement/update',  async (payload:IUpdatePayload,thunkAPI)=>{

   const path =`/partners/fees/disbursement_requests/${payload.requestId}?action=${payload.action}`

    return await axios
            .patch(REACT_APP_TARGET_SRV + path,{...payload}, {headers: {...HttpService.header()}})
            .then(async response=>{
                return response.data;
            })
            .catch((err)=>{
                const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.message;
                return thunkAPI.rejectWithValue(ErrCus);
            })     
})
interface IAddPayload {
    requestAmount: number;
    bankAccountNo: string;
    bankAccountName: string;
    bankName: string;
    bankBranch: string;
 }
 
export const addDisbursement = createAsyncThunk('Disbursement/add',  async (payload:IAddPayload,thunkAPI)=>{
    return await axios
        .post(REACT_APP_TARGET_SRV+`/partners/fees/disbursement_requests`,{...payload}, {headers: {...HttpService.header()}})
        .then(async response=>{
            return response.data;
        })
        .catch((err)=>{
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.localMessage===null?err.message:err.response.data.localMessage;
            return thunkAPI.rejectWithValue(ErrCus);
         })
})



export const fetchDisbursementPayment = createAsyncThunk('Disbursement/fetchDisbursementPayment',  async (payload:IDisbursement)=>{
    return await axios
        .get(REACT_APP_TARGET_SRV+`/partners/kafalah/payments/${payload?.uid}`, {headers: {...HttpService.header()}})
        .then(response=>{
            return{
                uid:response.data?.disbursementRequestId,
                paymentAmount: response.data?.paymentAmount,
                invoiceNo: response.data?.invoiceNo,
                invoiceFile: response.data?.invoiceFile,
                description: response.data?.note ,
                createdAt:response.data?.createdAt[2] + '/' + response.data?.createdAt[1] + '/' + response.data?.createdAt[0]
            } 
        })

})

interface IAddPayloadPayment {
    id:string;
    paymentAmount: number ;
    invoiceNo: string;
    invoiceFile: string;
    invoiceFileOri: File;
    bankName: string;
    bankAccountNo: string;
    bankAccountName: string;
    note: string;
}
export const addDisbursementPayment = createAsyncThunk('Disbursement/addPayment',  async (payload:IAddPayloadPayment)=>{
    const fileList = []; ///declare filelist Upload

    //set constant for upload file name
    if(payload.invoiceFile){
        fileList.push("invoiceFileOri");
    }

   //change the filename based on filename and that ext
    for (let i in fileList) {
        let fileNameAndExt = payload[`${fileList[i]}`]?.name.split('.')
        fileNameAndExt = fileList[i] +'.' + fileNameAndExt[fileNameAndExt.length-1];
        fileNameAndExt = fileNameAndExt.replace("Ori","")

        payload[fileList[i].replace("Ori","")]=fileNameAndExt         
    }

    
    return await axios
        .post(REACT_APP_TARGET_SRV+`/partners/kafalah/payments/${payload?.id}`,{...payload}, {headers: {...HttpService.header()}})
        .then(async response=>{
            console.log("response",response)
            const realPayload = {
                ...payload,
                uid: payload.id
            }

            console.log("realPayload",realPayload)
            console.log("filelist",fileList)
            await uploadFile(realPayload, fileList,"partners")
            return response.data;
        })
})


export const disbursementSlice = createSlice({
    name: 'Disbursement',
    initialState,
    reducers: {
        setDisbursementList: (state, action)=>{
            state.list=action.payload;
        },
        setDisbursementUI: (state, action)=>{
            state.ui = {
                mode: action.payload.mode,
                isOpen: action.payload.isOpen
            };
        },
        setDisbursementSelected: (state, action)=>{

            const {DisbursementSelected, DisbursementList} = action.payload;
            let DisbursementSelectedData = {} as IDisbursement;

            if (DisbursementSelected?.uid)
                DisbursementSelectedData = DisbursementList?.find(v=>
                v?.uid===DisbursementSelected?.uid
            );

            state.selected = {...DisbursementSelectedData }
        }
    },
    extraReducers: (builder) => {
        // list
        builder.addCase(fecthDisbursement.pending, (state)=>{
            state.loading=true;
            state.error='';
            state.list=[]
        })
        builder.addCase(fecthDisbursement.fulfilled, (state, action: PayloadAction<IDisbursement[]>) => {
            state.loading=false;
            state.list=action.payload;
            state.error='';
        })
        builder.addCase(fecthDisbursement.rejected, (state, action)=>{
            state.loading=false;
            state.list=[];
            state.error=action.error.message || 'Something went wrong';
        })
        // update
        builder.addCase(updateDisbursement.pending, (state)=>{

            state.loading=true;
            state.error='';
        })
        builder.addCase(updateDisbursement.fulfilled, (state, action)=>{
            state.loading=false;
            // const index = state.list.findIndex(val => val.id === action.payload.id);
            // state.list[index] = {
            //     ...state.list[index],
            //     ...action.payload
            // }
            state.error='';
        })
        builder.addCase(updateDisbursement.rejected, (state, action)=>{
            state.loading=false;
            // state.list=state.list;
            state.error=action.error.message || 'Something went wrong';
        })
        // insert
        builder.addCase(addDisbursement.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(addDisbursement.fulfilled, (state, action)=>{
            state.loading=false;
/* 
            const nextOrderNumber:number = state.list.length+1;
            state.list.push({...action.payload.data, id:nextOrderNumber}) */
            state.error='';
        })
        builder.addCase(addDisbursement.rejected, (state, action)=>{
            state.loading=false;
            // state.list=state.list;
            state.error=action.error.message || 'Something went wrong';
        })

         // get Disbursement Payment 
         builder.addCase(fetchDisbursementPayment.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(fetchDisbursementPayment.fulfilled, (state, action)=>{
            state.loading=false;
            state.disbursementPaymentHistory = action?.payload
            state.error='';
        })
        builder.addCase(fetchDisbursementPayment.rejected, (state, action)=>{
            state.loading=false;
            // state.list=state.list;
            state.error=action.error.message || 'Something went wrong';
        })
    }
})

export const { setDisbursementList, setDisbursementSelected, setDisbursementUI } = disbursementSlice.actions;
export default disbursementSlice.reducer;
