import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from "axios";
import HttpService from 'src/services/HttpService';
const {REACT_APP_TARGET_SRV} = process.env

interface IDatarole {
    id: string;
    name: string;
    description: string;
}
interface IAttributes {
    branch_id: string[];
    locale: string[];
    partnerId: any;
}
export type IDataList= {
    //make similar with API list (recommended)
    id: string;
    uid: string;
    username: string;
    name: string;
    email: string;
    attributes: IAttributes;
    branchId:string;
    branchName:string;
    userId:string;
    createdTimestamp: string;
    enabled:boolean;
    emailVerified: boolean;
    requiredEmailVerification:boolean;
    firstName: string;
    lastName: string;
    password:string;
    verifyPassword:string;
    roles:any|IDatarole[];
    tes:string[];
    partnerId:string;
}

type uiType = 'LIST' | 'EDIT' | 'ADD';

interface IUI {
    mode: uiType;
    isOpen: boolean;
}

interface IStateData {
    loading: boolean;
    list: IDataList[];
    error: string;
    ui: IUI;
    selected?: IStateData;

}

const initialState: IStateData= {
    loading: false,
    error: '',
    list : [],
    ui: {
        mode: 'LIST',
        isOpen: false
    }
}

export const fetchUserList= createAsyncThunk('userzamzam/fetchList', ()=>{
    return axios
       .get(REACT_APP_TARGET_SRV + `/users`, {headers: {...HttpService.header()}})
       .then(response=>{

           const responseIn =  response.data.map((v,i)=>{
               return {
                   ...v,
                   firstName:v.firstName,
                   lastName:v.lastName,
                   uid: v.id,
                   id: i+1,

               }
           })

           return responseIn;
       })
})




export interface IAddPayload {
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    password: string;
    verifyPassword: string;
    branchId: string;
    partnerId: string;
    roles:[{
        id: string;
        name: string;
        description: string;
    }]
    enabled: boolean;
}

export const addUser= createAsyncThunk('users/add',  async (payload:IAddPayload, thunkAPI)=>{
        return await axios
            .post(REACT_APP_TARGET_SRV + `/users`,{...payload}, {headers: {...HttpService.header()}})
            .then(async response=>{
                return response.data;
            })
            .catch((err)=>{
                //Checking condition if error from API then display from API, else from Thunk
                const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.message;
                return thunkAPI.rejectWithValue(ErrCus);
            })     
})

interface IUpdatePayload {
    uid: string;
    categoryName: string;
    referralFee: number;
    agentFee: number;
}
export const updateUser= createAsyncThunk('user/update',  async (payload:IUpdatePayload,thunkAPI)=>{

    return await axios
        .put(REACT_APP_TARGET_SRV + `/product_categories/${payload?.uid}`,{...payload}, {headers: {...HttpService.header()}})
        .then(async response=>{       
            return response.data;
        })
        .catch((err)=>{
            //Checking condition if error from API then display from API, else from Thunk
            const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.message;
            return thunkAPI.rejectWithValue(ErrCus);
        })     
})

export interface IPatchUserPayload {
    type: string;
    value: string;
    username:string;
}

export const patchUser= createAsyncThunk('user/patchuser',  async (payload:IPatchUserPayload,thunkAPI)=>{

    const path =(payload.type==="password")? `/users/update-password`: `/users/${payload.username}/${payload.type}`

    return await axios
            .patch(REACT_APP_TARGET_SRV + path,{...payload}, {headers: {...HttpService.header()}})
            .then(async response=>{
                return response.data;
            })
            .catch((err)=>{
                //Checking condition if error from API then display from API, else from Thunk
                const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.message;
                return thunkAPI.rejectWithValue(ErrCus);
            })     
})

export interface IDeleteUserPayload {
    username: string
}

export const deleteUser= createAsyncThunk('user/deleteuser',  async (payload:IDeleteUserPayload,thunkAPI)=>{

    return await axios
            .delete(REACT_APP_TARGET_SRV + `/users/${payload.username}`, {headers: {...HttpService.header()}})
            .then(async response=>{
                return response.data;
            })
            .catch((err)=>{
                //Checking condition if error from API then display from API, else from Thunk
                const ErrCus = (err.response.data===undefined || err.response.data===null)?err.message:err.response.data.message;
                return thunkAPI.rejectWithValue(ErrCus);
            })     
})




export interface ILoginPayload {
    username: string;
    password: string;
}

export const loginUser= createAsyncThunk('users/login',  async (payload:ILoginPayload, thunkAPI)=>{
        return await axios
            .post(REACT_APP_TARGET_SRV + `/users/login`,{...payload})
            .then(async response=>{
                return response.data;
            })
            .catch((err)=>{
                //Checking condition if error from API then display from API, else from Thunk
                const ErrCus = {
                    "code": err.code,
                    "status": err.status,
                    "message": err.response.data === undefined || err.response.data === null ? err.message : err.response.data.message
                };
                if (err.response && err.response.data !== undefined && err.response.data !== null) {
                    ErrCus["localMessage"] = err.response.data?.localMessage;
                }
                return thunkAPI.rejectWithValue(ErrCus);
            })     
})






export const dataSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserList: (state, action)=>{
            state.list=action.payload;
        },
        setUI: (state, action)=>{
            state.ui = {
                mode: action.payload.mode,
                isOpen: action.payload.isOpen
            };
        },
        setUserSelected: (state, action)=>{

            let realPayload = {...action.payload?.userSelected}
            if (action.payload?.userSelected?.attributes?.branch_id.length>0)
                realPayload.branchId = action.payload?.branchList.find(v=>action.payload?.userSelected?.attributes?.branch_id?.find(w => w === v.uid))
            state.selected = realPayload;
        }
    },
    extraReducers: (builder) => {
        // list
        builder.addCase(fetchUserList.pending, (state)=>{
            state.loading=true;
            state.error='';
            state.list=[]
        })
        builder.addCase(fetchUserList.fulfilled, (state, action: PayloadAction<IDataList[]>) => {
            state.loading=false;
            state.list=action.payload;
            state.error='';
        })
        builder.addCase(fetchUserList.rejected, (state, action)=>{
            state.loading=false;
            state.list=[];
            state.error=action.error.message || 'Something went wrong';
        })
        // insert
        builder.addCase(addUser.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(addUser.fulfilled, (state, action)=>{

            state.loading=false;
            //const nextOrderNumber:number = state.list.length+1;
            // state.list.push({...action.payload, id:nextOrderNumber})
            state.error='';
        })
        builder.addCase(addUser.rejected, (state, action)=>{

            state.loading=false;
            state.list=[];
            state.error= action.error.message ||'Something went wrong';
        })
        // update
        builder.addCase(updateUser.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(updateUser.fulfilled, (state, action)=>{
            state.loading=false;
            // const index = state.list.findIndex(val => val.id === action.payload.id);
            // state.list[index] = {
            //     ...state.list[index],
            //     ...action.payload
            // }
            state.error='';
        })
        builder.addCase(updateUser.rejected, (state, action)=>{
            state.loading=false;
            // state.list=state.list;
            state.error=action.error.message || 'Something went wrong';
        })
        // Patch
        builder.addCase(patchUser.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(patchUser.fulfilled, (state, action)=>{
            state.loading=false;
            //const nextOrderNumber:number = state.list.length+1;
            // state.list.push({...action.payload, id:nextOrderNumber})
            state.error='';
        })
        builder.addCase(patchUser.rejected, (state, action)=>{
            state.loading=false;
            state.list=[];
            state.error= action.error.message ||'Something went wrong';
        })
        // delete USer
        builder.addCase(deleteUser.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(deleteUser.fulfilled, (state, action)=>{
            state.loading=false;
            //const nextOrderNumber:number = state.list.length+1;
            // state.list.push({...action.payload, id:nextOrderNumber})
            state.error='';
        })
        builder.addCase(deleteUser.rejected, (state, action)=>{
            state.loading=false;
            state.list=[];
            state.error= action.error.message ||'Something went wrong';
        })
         // Login
        builder.addCase(loginUser.pending, (state)=>{
            state.loading=true;
            state.error='';
        })
        builder.addCase(loginUser.fulfilled, (state, action)=>{

            state.loading=false;
           // state.disbursementPaymentHistory = action?.payload
            state.error='';
        })
        builder.addCase(loginUser.rejected, (state, action)=>{

            state.loading=false;
            state.list=[];
            state.error= action.error.message ||'Something went wrong';
        })
    }
})


export const { setUserList, setUserSelected, setUI } = dataSlice.actions;
export default dataSlice.reducer;


