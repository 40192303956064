import { ReactNode } from 'react';

import AllMenus from 'src/constants/Menus/AllMenus';
import ROLE_BRANCH_ADMIN from 'src/constants/Menus/ROLE_BRANCH_ADMIN';
import ROLE_PARTNER from 'src/constants/Menus/ROLE_PARTNER';
//import UserService from 'src/services/UserService'
import {useAppDispatch, useAppSelector} from "../../../../store/Hook";



//const [profile, setProfile] = useState(UserService.getProfile())
export interface MenuItem {
  url?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  activeLabel: string;
}

export interface MenuItems {
  items: MenuItem[];
  groupLabel: string;
}

const MenuItems = ():MenuItems[] => {

//get role from  user profile 
const userRole = useAppSelector(state => state.auth.role)
const {REACT_APP_ROLE_SA, REACT_APP_ROLE_BA} = process.env

  // const dispatch = useAppDispatch();
  // const menus = dispatch(fetchMenus())
  //return AllMenus;
  //return UserService.getUserRole().toString()===REACT_APP_ROLE_SA?AllMenus:ROLE_BRANCH_ADMIN;
  return userRole===REACT_APP_ROLE_SA?AllMenus:(userRole===REACT_APP_ROLE_BA)?ROLE_BRANCH_ADMIN:ROLE_PARTNER;
}

export default MenuItems;
