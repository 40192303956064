import BrightnessLowTwoToneIcon from "@mui/icons-material/BrightnessLowTwoTone";
import DesignServicesTwoToneIcon from "@mui/icons-material/DesignServicesTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import TableChartTwoToneIcon from "@mui/icons-material/TableChartTwoTone";
import BallotTwoToneIcon from "@mui/icons-material/BallotTwoTone";
import HowToVoteTwoToneIcon from "@mui/icons-material/HowToVoteTwoTone";
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import HealingOutlinedIcon from '@mui/icons-material/HealingOutlined';
import { AccountBalanceWalletRounded, PaymentsRounded } from "@mui/icons-material";
import PaymentIcon from "@mui/icons-material/Payment";

export default [
  {
    groupLabel: 'Dashboards',
    items: [
      {
        activeLabel: 'Dashboard',
        url: '/dashboards/current-status',
        icon: BrightnessLowTwoToneIcon
      },
    ]
  },
  {
    groupLabel: 'Transaksi',
    items: [
      {
        activeLabel: 'Data Mitra',
        url: '/management/agents',
        icon: DesignServicesTwoToneIcon
      },
      {
        activeLabel: 'Data Jamaah',
        icon: AccountCircleTwoToneIcon,
        url: '/management/pilgrims'
      },
      {
        activeLabel: 'Data Paket',
        icon: TableChartTwoToneIcon,
        url: '/management/products'
      },
      {
        activeLabel: 'Data Kafalah',
        icon: PaymentsRounded,
        url: '/management/disbursement'
      },
      {
        activeLabel: 'Data Refund',
        icon: PaymentIcon,
        url: '/management/refunds'
      },
    ],
  },
  /* {
    groupLabel: 'Kafalah',
    items: [
      {
        activeLabel: 'Kafalah Mitra/Sub Mitra',
        icon: BeachAccessTwoToneIcon,
        url: '/management/kafalah-agents'
      },
      {
        activeLabel: 'Transaksi Kafalah',
        icon: TableChartTwoToneIcon,
        url: '/management/kafalah-trx'
      },
    ]
  }, */
  {
    groupLabel: 'Setting',
    items: [
      {
        activeLabel: 'Kategori',
        icon: BallotTwoToneIcon,
        url: '/management/product-categories'
      },
      {
        activeLabel: 'Tipe Paket',
        icon: BallotTwoToneIcon,
        url: '/management/product-types'
      },
      {
        activeLabel: 'Cabang',
        icon: TableChartTwoToneIcon,
        url: '/management/branches'
      },
      {
        activeLabel: 'Users',
        icon: TableChartTwoToneIcon,
        url: '/management/admins'
      },
    ]
  },
  {
    groupLabel: 'Master Data',
    items: [
      {
        activeLabel: 'Hotel',
        icon: ApartmentOutlinedIcon,
        url: '/management/masterdata/hotel'
      },
      {
        activeLabel: 'Maskapai',
        icon: AirplaneTicketOutlinedIcon,
        url: '/management/masterdata/maskapai'
      },
      {
        activeLabel: 'Visa Provider',
        icon: CreditCardOutlinedIcon,
        url: '/management/masterdata/visaprovider'
      },
      {
        activeLabel: 'Insurance',
        icon: HealingOutlinedIcon,
        url: '/management/masterdata/insurance'
      },
     /*  {
        activeLabel: 'Bus',
        icon: AirportShuttleOutlinedIcon,
        url: '/management/masterdata3'
      } */
    ]
  },
  {
    groupLabel: 'Logout',
    items: [{
      activeLabel: 'Logout',
      icon: HowToVoteTwoToneIcon,
      url: '/management/logout'
    }]
  }
];
