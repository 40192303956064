import axios from "axios";
import HttpService from "./HttpService";
import cusToast from 'src/utils/cusToast';
const {REACT_APP_TARGET_SRV} = process.env

const uploadFile = async (payload, fileList,pathAPI)=>{
  let axiosConfig = {
    headers: {  
      'Content-Type': 'image/jpeg'
    }
  };

  for (let i in fileList) {
    if (payload[`${fileList[i]}`]?.name) {
    // jika update namanya di pas upload maka di sini ubahny filenamennya   
      let fileNameAndExt = payload[`${fileList[i]}`]?.name.split('.')
      fileNameAndExt = fileList[i] +'.' + fileNameAndExt[fileNameAndExt.length-1];
      fileNameAndExt =fileNameAndExt.replace("Ori","") 
      const finPayload={
        ...payload,
        fileNameAndExt:fileNameAndExt
      }
      console.log("finPayload", finPayload)
      console.log(finPayload[`${fileList[i]}`])
      

      await axios.get(REACT_APP_TARGET_SRV+`/` + pathAPI + `/temporary-upload-url/` + finPayload?.uid + `/` + fileNameAndExt, {headers: {...HttpService.header()}})
        .then(async v => {
            await axios.put(v?.data, finPayload[`${fileList[i]}`],axiosConfig)
              .then(v => {
                if (v?.status !== 200) {
                  cusToast.closeToast()
                  cusToast.newToast("Data berhasil tersimpan, namun Gagal upload file " +finPayload[`${fileList[i]}`]?.name,"ERROR")
                }
              })
              .catch(e => {
                cusToast.closeToast()
                cusToast.newToast("Data berhasil tersimpan, namun Gagal upload file " +finPayload[`${fileList[i]}`]?.name,"ERROR")
              })

        })
        .catch(e => {
         cusToast.closeToast()
         cusToast.newToast("Data berhasil tersimpan, namun Gagal upload file " +finPayload[`${fileList[i]}`]?.name,"ERROR")
        })
    }
  }
}

export default uploadFile;
