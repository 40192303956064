import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import cusToast from 'src/utils/cusToast';
import { patchUser } from 'src/store/Userzamzam';
import numberFormat from 'src/utils/NumberFormat';


import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField
} from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import NoEncryptionGmailerrorredOutlinedIcon from '@mui/icons-material/NoEncryptionGmailerrorredOutlined';
import { useAppSelector,useAppDispatch } from 'src/store/Hook';
import { useNavigate } from 'react-router-dom';
import Onlinesignal from 'src/components/Onlinesignal';
import Notification from '../Buttons/Notifications'
import { unwrapResult }  from '@reduxjs/toolkit';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);




function HeaderUserbox() {
  // const [profile, setProfile] = useState(UserService.getProfile())
  const profile = useAppSelector(state => state.auth)
  const [openDialog, setOpenDialog] = useState(false);
  const [pwd, setPwd] = useState("");
  const [pwd2, setPwd2] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const kafBalance= profile.kafBalance;//useAppSelector(state => state.auth.kafBalance)
  const branchFees= profile.branchFees; //useAppSelector(state => state.auth.branchFees)
  const userRole = profile.role

  const user =
  {
    name: profile?.username,
    // fullName: profile?.fullName,
    fullName: profile?.name,
     avatar: '/static/images/placeholders/logo/jamaah2.png',
    jobtitle: profile?.role
  };

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const handleCloseDialog = (): void => {
    setOpenDialog(false);
    setPwd("")
    setPwd2("")
  };

  const logOut=() =>{
    //alert("peaaa")
    navigate('/management/logout'); 
  }
  const changePass=() =>{
    setOpenDialog(true)
    //navigate('/management/profile'); 
  }

  const submitPass = () =>{
      cusToast.showToast()  
        const payload = {
            type:"password",
            value:pwd,
            username:''
        }

        if(pwd==""){
        cusToast.updateToast("Inputan penggantian password tidak boleh kosong","INFORMASI")  
          return
        }
        if(pwd2==""){
          cusToast.updateToast("Inputan konfirm password tidak boleh kosong","INFORMASI")  
            return
          }
  
  

      if(pwd===pwd2){
        dispatch(patchUser(payload))
        .then(unwrapResult)
        .then(v=>{
                cusToast.updateToast("Berhasil memperbaharui password, Silahkan Logout dan Login Ulang","SUCCESS")  
               
        })
        .catch((obj)=>{
            //console.log("catch",{obj});
            cusToast.updateToast("Gagal memperbaharui password, coba ulangi. " + obj,"ERROR");
        })
        handleCloseDialog()
       }
      else
      {
        cusToast.updateToast("Inputan Password dan Konfirm Password belum sesuai","INFORMASI")   
      }    
}

  
  return (
    <> <Notification/>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Onlinesignal/>
        <Avatar variant="rounded" alt={user.name} src={user.avatar} 
        sx={{ border: '4px solid primary' }}/>
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">User : {(user.name==="token expired")?logOut():user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {""}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user.name} src={user.avatar} 
          sx={{ border: '4px solid primary' }}/>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.fullName}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
         <List sx={{ p: 1 }} component="nav">
         {/*  <ListItem button to="/management/profile/details" component={NavLink}>
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary="My Profile" />
          </ListItem>
          <ListItem
            button
            to="/management/profile/settings"
            component={NavLink}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary="Account Settings" />
          </ListItem> */}
           {/* <ListItem
            button
          >
            <MonetizationOnOutlinedIcon fontSize="medium" />
            {userRole==="ROLE_BRANCH_ADMIN"?
            <ListItemText primary={numberFormat(branchFees||0)} />
            :<ListItemText primary={numberFormat(kafBalance["balance"]||0)} />
            }
          </ListItem> */}
          <ListItem
            button
            onClick={()=>{changePass()}}
          >
            <NoEncryptionGmailerrorredOutlinedIcon fontSize="small" />
            <ListItemText primary="Change Password" />
          </ListItem>
         
        </List> 
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth 
            onClick={(e)=>{logOut()}}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
      <Dialog  open={openDialog} onClose={handleCloseDialog} fullWidth>
        {/* <DialogTitle>Subscribe</DialogTitle>   */}
            <DialogContent  >  
              <h3><i>Change Password</i></h3>          
            <TextField
                  fullWidth
                  label="Masukan password baru anda"
                  size="small"
                  onBlur={(e)=> setPwd(e.target.value)}
                  type="password"
                />
             <Divider sx={{ p:1,background:"white" }}   />
             <TextField
                  fullWidth
                  label="Konfirm password baru anda"
                  size="small"
                  onBlur={(e)=> setPwd2(e.target.value)}
                  type="password"
                />
             <Divider sx={{ p:1,background:"white" }}   />
            <Button  color="primary"  variant="contained"  fullWidth
            onClick={(e)=>{submitPass()}}>
            Submit
            </Button>
            </DialogContent>
        </Dialog>
    </>
  );
}

export default HeaderUserbox;
