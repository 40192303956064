import React, { useEffect, useState } from 'react'
import WifiRoundedIcon from '@mui/icons-material/WifiRounded';
import WifiOffRoundedIcon from '@mui/icons-material/WifiOffRounded';

const getOnLineStatus = () =>
    typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
      ? navigator.onLine
      : true;

function Onlinesignal() {
    

    let [online, isOnline] = useState(navigator.onLine);

    const setOnline = () => {
      console.log('We are online!');
      isOnline(true);
    };
    const setOffline = () => {
      console.log('We are offline!');
      isOnline(false);
    };

    // Register the event listeners
  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    // cleanup if we unmount
    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    }
  }, []);


  return (
    <>{online?
    <WifiRoundedIcon fontSize='small' style={{ color: "green" }}/>:
    <WifiOffRoundedIcon fontSize="small" style={{ color: "red" }}/>}</>
  )
}

export default Onlinesignal