import {
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { styled } from '@mui/material/styles';
import {useAppDispatch, useAppSelector} from "../../../../../store/Hook";
import {login,getMyProfile,getKafBalance} from 'src/store/Auth'
import numberFormat from 'src/utils/NumberFormat';

import { formatDistance, subDays } from 'date-fns';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);



function HeaderNotifications() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [countData, setCountData] = useState(0);
  const [idDis, setIdDis] = useState("")
  //const [dataInState, setDataInState] = useState([{status:"xxx",partnerName:"eee",branchName:"wwww",requestedAmount:222,requestedAt:[2023,4,18,8,10,59,311646415]}])
  const [dataInState, setDataInState] = useState([])
  const dispatch = useAppDispatch();
  const apiKey = useAppSelector(state => state.auth)
  const apiKey2 = apiKey.apiKey
  const {REACT_APP_TARGET_SRV} = process.env


  /* const dataArrsample=[{"id":"643e509332af022b5e33c183","partnerId":"643ac31fbb8d9c583e570c3c","partnerName":"bengkulu_partner","branchId":"63a740173a311c1ddc16b5de","branchName":"Retali Bengkulu","city":"Bengkulu","province":"Sumatera Barat","requestedAmount":35000,"bankName":"fff","bankAccountNo":"wege","bankAccountName":"dd","bankBranch":"sdgd","status":"PENDING_APPROVAL","requestedBy":"bengkulu_partner","requestedAt":[2023,4,18,8,10,59,311646415]},
                  {"id":"643e509332af022b5e33x183","partnerId":"643ac31fbb8d9c583e570c3c","partnerName":"bengkulu_partner","branchId":"63a740173a311c1ddc16b5de","branchName":"Retali Bengkulu","city":"Bengkulu","province":"Sumatera Barat","requestedAmount":35000,"bankName":"fff","bankAccountNo":"wege","bankAccountName":"dd","bankBranch":"sdgd","status":"PENDING_APPROVAL","requestedBy":"bengkulu_partner","requestedAt":[2023,4,18,8,10,59,311646415]},
                  {"id":"643e509332af022b5e33c185","partnerId":"643ac31fbb8d9c583e570c3c","partnerName":"bengkulu_partner","branchId":"63a740173a311c1ddc16b5de","branchName":"Retali Bengkulu","city":"Bengkulu","province":"Sumatera Barat","requestedAmount":35000,"bankName":"fff","bankAccountNo":"wege","bankAccountName":"dd","bankBranch":"sdgd","status":"PENDING_APPROVAL","requestedBy":"bengkulu_partner","requestedAt":[2023,4,18,8,10,59,311646415]}]
 */
  
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
    setCountData(0)
  };


  function handleStream(data){
    const has =JSON.parse(JSON.parse(JSON.stringify(data.data)))
    if(idDis !== has.id){
      setIdDis(has.id)
      setCountData((c)=>c+1)
      setDataInState(prevState => [has,...prevState]);
    }
  }

   useEffect(() => {
  
    if (apiKey2!==""){ //cuz first render is null so must add condition

        const url = REACT_APP_TARGET_SRV + "/partners/fees/disbursement_requests/data/stream/" + apiKey2
        const source = new EventSource(url);

        source.onmessage = e =>{handleStream(e)}

        source.onerror = e =>{
          source.close()
        }

        return()=>{
          source.close()
        }  
    }

  }, [apiKey2]) 
  


  

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={countData}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box sx={{ p: 2 }} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">Notifications</Typography>
        </Box>
        <Divider />
        <List sx={{ p: 0 }}>
          {dataInState.map((v)=>{
            return (
              <>
             <ListItem sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}>
             <Box flex="1">
               <Box display="flex" justifyContent="space-between">
                 <Typography sx={{ fontWeight: 'bold' }}>
                   {v.status}
                 </Typography>
                 <Typography variant="caption" sx={{ textTransform: 'none' }}>
                  {/*   {formatDistance(subDays(new Date(), 3), new Date(), {
                     addSuffix: true
                   })}  */}
                    {v.requestedAt[2] + '/' + v.requestedAt[1] + '/' + v.requestedAt[0]}
                 </Typography>
               </Box>
                   <Typography
                     component="span"
                     variant="body2"
                     color="text.secondary"
                   >
                     {' '}
                     Status baru di dalam data pencairan <br></br>
                     Cabang : {v.branchName} , Nama Mitra : {v.partnerName} <br></br>
                     <b>Jumlah Uang : {numberFormat(v.requestedAmount||0)}</b>
                   </Typography>
             </Box>
              </ListItem>
              <Divider />
              </>
            )
          })}
          
          
        </List>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
