import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import HttpService from 'src/services/HttpService';
import { IBranch } from './BranchSlice';
import uploadFile from '../services/uploadFile';
const { REACT_APP_TARGET_SRV } = process.env;

interface IUserAccount {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  enabled: boolean;
  requiredEmailVerification: boolean;
  password: string;
  verifyPassword: string;
  // List<KeycloakRealmRole> roles;
  branchId: string;
  partnerId?: string;
}

export type IPartner = {
  id: string;
  uid: string;
  fullName: string;
  gender: boolean;
  mobileNo: string;
  email: string;
  dateOfBirth: Date;
  occupation: string;
  streetAddress: string;
  city: string;
  district: string;
  subDistrict: string;
  province: string;
  postalCode: string;

  // partnerRef:ISubPartner;

  branch: IBranch;
  partnerRefId: string;
  partnerRefName: string;
  partnerRefBranchId: string;
  partnerRefBranchName: string;
  partnerRefBranchAddress: string;
  partnerRefBranchCity: string;
  partnerBranchId: string;
  partnerBranchName: string;
  partnerBranchAddress: string;
  partnerBranchCity: string;
  username: string;

  icNo: string;
  icFileOri: File;
  icFile: string;
  icMimeType: string;
  icFileSize: number;

  passportNo: string;
  passportFileOri: File;
  passportFile: string;
  passportMimeType: string;
  passportFileSize: number;

  photoFileOri: File;
  photoFile: string;
  photoMimeType: string;
  photoFileSize: number;

  bankAccountNo: string;
  bankAccountName: string;
  bankName: string;
  bankBranch: string;

  branchName: string;
  //   branchId: string;
  branchId: any;
  branchAddress: string;
  branchCity: string;
  active: boolean;
  userAccount: IUserAccount;
  kafalahBalance: number;
};

export type IPage = {
  limit: number;
  page: number;
  search: string;
};

type uiType = 'LIST' | 'EDIT' | 'ADD';

interface IPartnerUI {
  mode: uiType;
  isOpen: boolean;
}

interface IPartners {
  loading: boolean;
  list: IPartner[];
  error: string;
  ui: IPartnerUI;
  selected?: IPartner;
}

const initialState: IPartners = {
  loading: false,
  error: '',
  list: [],
  ui: {
    mode: 'LIST',
    isOpen: false
  }
};

export const fetchPartners = createAsyncThunk(
  'partner/fetchList',
  async (payload: IPage, thunkAPI) => {
    let url = `${REACT_APP_TARGET_SRV}/partners`;
    if (payload.search !== '') {
      url += `?name=${payload.search}`;
    }

    if (payload.search !== '') {
      url += `&page=${payload.page ? payload.page : 1}&limit=${
        payload.limit ? payload.limit : 5
      }`;
    } else {
      url += `?page=${payload.page ? payload.page : 1}&limit=${
        payload.limit ? payload.limit : 5
      }`;
    }

    return axios
      .get(url, { headers: { ...HttpService.header() } })
      .then((response) => {
        return response.data.map((v, i) => {
          return {
            ...v,
            uid: v?.id,
            id: i + 1,
            bankAccountPartner: v?.bankAccountOfficePartner,
            bankBranch: v?.bankAccountOfficeBranch,
            partnerRefName: v?.referralName || '',
            userAccount: {
              username: v.username,
              enabled: v.active
            },
            branchId: {
              uid: v?.branchId,
              name: v?.branchName
            }
          };
        });
      })
      .catch((err) => {
        const ErrCus =
          err.response.data === undefined || err.response.data === null
            ? err.message
            : err.response.data.message;
        return thunkAPI.rejectWithValue(ErrCus);
      });
  }
);

export const updatePartner = createAsyncThunk(
  'partner/update',
  async (payload: IPartner, thunkAPI) => {
    const fileList = []; ///declare filelist Upload

    //set constant for upload file name
    if (payload.icFile) {
      fileList.push('icFileOri');
    }
    if (payload.passportFile) {
      fileList.push('passportFileOri');
    }
    if (payload.photoFile) {
      fileList.push('photoFileOri');
    }
    //const fileList = ["nikFile", "passportFile", "photoFile"]

    //change the filename based on filename and that ext
    for (let i in fileList) {
      let fileNameAndExt = payload[`${fileList[i]}`]?.name.split('.');
      fileNameAndExt =
        fileList[i] + '.' + fileNameAndExt[fileNameAndExt.length - 1];
      fileNameAndExt = fileNameAndExt.replace('Ori', '');

      payload[fileList[i].replace('Ori', '')] = fileNameAndExt;
    }

    return await axios
      .put(
        REACT_APP_TARGET_SRV + `/partners/${payload?.uid}`,
        { ...payload },
        { headers: { ...HttpService.header() } }
      )
      .then(async (response) => {
        await uploadFile(payload, fileList, 'partners');
        return response.data;
      })
      .catch((err) => {
        //console.log("--fromThunkAPI---",err);
        //Checking condition if error from API then display from API, else from Thunk
        const ErrCus =
          err.response.data === undefined || err.response.data === null
            ? err.message
            : err.response.data.localMessage === null
            ? err.message
            : err.response.data.localMessage;
        return thunkAPI.rejectWithValue(ErrCus);
      });
  }
);

export const addPartner = createAsyncThunk(
  'partner/add',
  async (payload: IPartner, thunkAPI) => {
    return await axios
      .post(
        REACT_APP_TARGET_SRV + `/partners`,
        { ...payload },
        { headers: { ...HttpService.header() } }
      )
      .then(async (response) => {
        return response.data;
      })
      .catch((err) => {
        const ErrCus =
          err.response.data === undefined || err.response.data === null
            ? err.message
            : err.response.data.messages.join('\n');
        return thunkAPI.rejectWithValue(ErrCus);
      });
  }
);

export const partnerSlice = createSlice({
  name: 'Partner',
  initialState,
  reducers: {
    setPartnerList: (state, action) => {
      state.list = action.payload;
    },
    setPartnerUI: (state, action) => {
      state.ui = {
        mode: action.payload.mode,
        isOpen: action.payload.isOpen
      };
    },
    setPartnerSelected: (state, action) => {
      const { partnerSelected, partnerList } = action.payload;
      let partnerSelectedData = <IPartner>{};

      if (partnerSelected?.uid)
        partnerSelectedData = partnerList?.find(
          (v) => v?.uid === partnerSelected?.uid
        );

      state.selected = { ...partnerSelectedData };
    }
  },
  extraReducers: (builder) => {
    // list
    builder.addCase(fetchPartners.pending, (state) => {
      state.loading = true;
      state.error = '';
      state.list = [];
    });
    builder.addCase(
      fetchPartners.fulfilled,
      (state, action: PayloadAction<IPartner[]>) => {
        state.loading = false;
        state.list = action.payload;
        state.error = '';
      }
    );
    builder.addCase(fetchPartners.rejected, (state, action) => {
      state.loading = false;
      state.list = [];
      state.error = action.error.message || 'Something went wrong';
    });
    // update
    builder.addCase(updatePartner.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(updatePartner.fulfilled, (state, action) => {
      state.loading = false;
      // const index = state.list.findIndex(val => val.id === action.payload.id);
      // state.list[index] = {
      //     ...state.list[index],
      //     ...action.payload
      // }
      state.error = '';
    });
    builder.addCase(updatePartner.rejected, (state, action) => {
      state.loading = false;
      // state.list=state.list;
      state.error = action.error.message || 'Something went wrong';
    });
    // insert
    builder.addCase(addPartner.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(addPartner.fulfilled, (state, action) => {
      state.loading = false;

      const nextOrderNumber: number = state.list.length + 1;
      state.list.push({ ...action.payload.data, id: nextOrderNumber });
      state.error = '';
    });
    builder.addCase(addPartner.rejected, (state, action) => {
      state.loading = false;
      // state.list=state.list;
      state.error = action.error.message || 'Something went wrong';
    });
  }
});

export const { setPartnerList, setPartnerSelected, setPartnerUI } =
  partnerSlice.actions;
export default partnerSlice.reducer;
