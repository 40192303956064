import {createAsyncThunk, createSlice,PayloadAction} from '@reduxjs/toolkit';
import axios from "axios";
import HttpService from 'src/services/HttpService';
const {REACT_APP_TARGET_SRV} = process.env
console.log({REACT_APP_TARGET_SRV})

interface IAuth {
    userId:string;
    apiKey:string;
    username: string;
    name: string;
    branchId:string;
    branchName:string;
    kafalahAllocationFee:number;
    role:string;
    partnerRefId:string;
    isAuthenticated: boolean;
    kafBalance: IkafBalance[];
    branchFees:number;
    balance: number;
}

interface IkafBalance{
  partnerId: string;
  fullName: string;
  mobileNo: string;
  occupation: string;
  city: string;
  province: string;
  username: string;
  branchName: string;
  refPartnerName: string;
  icNo: string;
  balance: number;
}

const initialState: IAuth = {
    userId:'',
    apiKey:'',
    username:'',
    name:'',
    branchId:'',
    branchName:'',
    kafalahAllocationFee:0,
    role:'',
    partnerRefId:'',
    isAuthenticated: false,
    kafBalance: [],
    branchFees:0,
    balance:0,
}

export const getMyProfile= createAsyncThunk('userzamzam/fetchMe', ()=>{
    return axios
      .get(REACT_APP_TARGET_SRV + `/users/me`, {headers: {...HttpService.header()}})
       .then(function (response) {
            return response.data;
      })
})

export const getKafBalance= createAsyncThunk('userzamzam/kafBalance', ()=> {
    return axios
      .get(REACT_APP_TARGET_SRV + `/partners/current_user_balance`, {headers: {...HttpService.header()}})
       .then(function (response) {
            return response.data;
      })
})

export const getBranchFees= createAsyncThunk('userzamzam/branchFees', ()=>{
    return axios
      .get(REACT_APP_TARGET_SRV + `/branches/current_user_branch`, {headers: {...HttpService.header()}})
       .then(function (response) {
            return response.data;
      })
})

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state)=> {
            state.isAuthenticated=true
        },
        logout: (state)=>{
            state.isAuthenticated=false
        }
    },
    extraReducers: (builder) => {
    
        builder.addCase(getMyProfile.pending, (state)=>{
            state.username=""
        })
        builder.addCase(getMyProfile.fulfilled, (state, action) => {
            const roleGab = action.payload.data.roles.join() //cauze redux is immutable type. so declare var here
            state.userId=action.payload.data.userId;
            state.apiKey=action.payload.data.apiKey;
            state.username=action.payload.data.username;
            state.branchId=action.payload.data.branchId;
            state.branchName=action.payload.data.branchName;
            state.name=action.payload.data.name;
            state.partnerRefId=action.payload.data.partnerId;
            state.kafalahAllocationFee=action.payload.data.kafalahAllocationFee;
            state.role=roleGab;
        })
        builder.addCase(getMyProfile.rejected, (state, action)=>{
            state.username="token expired" // flag for handling if token expired
        })

        builder.addCase(getKafBalance.pending, (state)=>{
            state.kafBalance=[]
        })
        builder.addCase(getKafBalance.fulfilled, (state, action: PayloadAction<IkafBalance[]>) => {
            state.kafBalance=action.payload;
        })
        builder.addCase(getKafBalance.rejected, (state, action)=>{
            state.kafBalance=[]
        })

        builder.addCase(getBranchFees.pending, (state)=>{
            state.branchFees=0
        })
        builder.addCase(getBranchFees.fulfilled, (state, action) => {
            state.branchFees=action.payload.balance;
        })
        builder.addCase(getBranchFees.rejected, (state, action)=>{
            state.branchFees=0
        })
    }
})

export const { login, logout } = authSlice.actions;

export default authSlice;