import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import HttpService from 'src/services/HttpService';
const { REACT_APP_TARGET_SRV } = process.env;

export type IProductCategory = {
  id: string;
  uid: string;
  categoryName: string;
  agentPercentageFee: number;
  referralPercentageFee: number;
  branchPercentageFee: number;
  kafalahAllocationFee: number;
  infantAgentPercentageFee: number;
  infantReferralPercentageFee: number;
  infantBranchPercentageFee: number;
  infantKafalahAllocationFee: number;
  active: boolean;
  createdBy: string;
  createdAt: Date;
  updatedBy: Date;
  updatedAt: Date;
};
type uiType = 'LIST' | 'EDIT' | 'ADD';

interface IProductCategoryUI {
  mode: uiType;
  isOpen: boolean;
  hasInsertRole: boolean;
  hasUpdateRole: boolean;
  hasViewRole: boolean;
}

interface IProductCategories {
  loading: boolean;
  list: IProductCategory[];
  error: string;
  ui: IProductCategoryUI;
  selected?: IProductCategory;
}

const initialState: IProductCategories = {
  loading: false,
  error: '',
  list: [],
  ui: {
    mode: 'LIST',
    isOpen: false,
    hasInsertRole: false,
    hasUpdateRole: false,
    hasViewRole: false
  }
};

export const fetchProductCategories = createAsyncThunk(
  'productCategory/fetchList',
  () => {
    return axios
      .get(REACT_APP_TARGET_SRV + `/product_categories`, {
        headers: { ...HttpService.header() }
      })
      .then((response) => {
        return response.data.map((v, i) => {
          return {
            ...v,
            uid: v.id,
            id: i + 1
          };
        });
      });
  }
);
interface IUpdatePayload {
  uid: string;
  categoryName: string;
  agentPercentageFee: number;
  referralPercentageFee: number;
  branchPercentageFee: number;
  kafalahAllocationFee: number;
  infantAgentPercentageFee: number;
  infantReferralPercentageFee: number;
  infantBranchPercentageFee: number;
  infantKafalahAllocationFee: number;
}
export const updateProductCategory = createAsyncThunk(
  'productCategory/update',
  async (payload: IUpdatePayload, thunkAPI) => {
    return await axios
      .put(
        REACT_APP_TARGET_SRV + `/product_categories/${payload?.uid}`,
        { ...payload },
        { headers: { ...HttpService.header() } }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        //Checking condition if error from API then display from API, else from Thunk
        const ErrCus =
          err.response.data === undefined || err.response.data === null
            ? err.message
            : err.response.data.messages.join('\n');
        return thunkAPI.rejectWithValue(ErrCus);
      });
  }
);

interface IAddPayload {
  categoryName: string;
  agentPercentageFee: number;
  referralPercentageFee: number;
  branchPercentageFee: number;
  kafalahAllocationFee: number;
  infantAgentPercentageFee: number;
  infantReferralPercentageFee: number;
  infantBranchPercentageFee: number;
  infantKafalahAllocationFee: number;
}

export const addProductCategory = createAsyncThunk(
  'productCategory/add',
  async (payload: IAddPayload, thunkAPI) => {
    return await axios
      .post(
        REACT_APP_TARGET_SRV + `/product_categories`,
        { ...payload },
        { headers: { ...HttpService.header() } }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        //Checking condition if error from API then display from API, else from Thunk
        const ErrCus =
          err.response.data === undefined || err.response.data === null
            ? err.message
            : err.response.data.messages.join('\n');
        return thunkAPI.rejectWithValue(ErrCus);
      });
  }
);

export const prodCategorySlice = createSlice({
  name: 'productCategory',
  initialState,
  reducers: {
    setProductCategoryList: (state, action) => {
      state.list = action.payload;
    },
    setProductCategoryUI: (state, action) => {
      state.ui = {
        mode: action.payload.mode,
        isOpen: action.payload.isOpen,
        hasInsertRole: false,
        hasUpdateRole: false,
        hasViewRole: false
      };
    },
    setProductCategorySelected: (state, action) => {
      state.selected = action.payload;
    }
  },
  extraReducers: (builder) => {
    // list
    builder.addCase(fetchProductCategories.pending, (state) => {
      state.loading = true;
      state.error = '';
      state.list = [];
    });
    builder.addCase(
      fetchProductCategories.fulfilled,
      (state, action: PayloadAction<IProductCategory[]>) => {
        state.loading = false;
        state.list = action.payload;
        state.error = '';
      }
    );
    builder.addCase(fetchProductCategories.rejected, (state, action) => {
      state.loading = false;
      state.list = [];
      state.error = action.error.message || 'Something went wrong';
    });
    // update
    builder.addCase(updateProductCategory.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(updateProductCategory.fulfilled, (state, action) => {
      state.loading = false;
      const index = state.list.findIndex((val) => val.id === action.payload.id);
      state.list[index] = {
        ...state.list[index],
        ...action.payload
      };
      state.error = '';
    });
    builder.addCase(updateProductCategory.rejected, (state, action) => {
      state.loading = false;
      // state.list=state.list;
      state.error = action.error.message || 'Something went wrong';
    });
    // insert
    builder.addCase(addProductCategory.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(addProductCategory.fulfilled, (state, action) => {
      state.loading = false;
      const nextOrderNumber: number = state.list.length + 1;
      state.list.push({ ...action.payload.data, id: nextOrderNumber });
      state.error = '';
    });
    builder.addCase(addProductCategory.rejected, (state, action) => {
      state.loading = false;
      // state.list=state.list;
      state.error = action.error.message || 'Something went wrong';
    });
  }
});

export const {
  setProductCategoryList,
  setProductCategorySelected,
  setProductCategoryUI
} = prodCategorySlice.actions;
export default prodCategorySlice.reducer;
