import { useRoutes } from 'react-router-dom';
import routes from './router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ThemeProvider from './theme/ThemeProvider';
import { Box, CssBaseline } from '@mui/material';
import { login, getMyProfile, getKafBalance, getBranchFees } from 'src/store/Auth'
import { useAppDispatch } from 'src/store/Hook'
import { useEffect } from "react";
import { ConfirmProvider } from 'material-ui-confirm';

import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'src/store';
import Authenticated from 'src/Authenticate';
import { ToastContainer } from "react-toastify";
import { SidebarProvider } from 'src/contexts/SidebarContext';

import { QueryClientProvider, QueryClient } from 'react-query'

import Login from 'src/content/applications/Login'
import { Toaster } from 'react-hot-toast';


const queryClient = new QueryClient()

const App = () => {
  const dispatch = useAppDispatch();
  const content = useRoutes(routes);

  useEffect(() => {
    dispatch(login())
    dispatch((getMyProfile()))
    dispatch(getKafBalance())
    dispatch(getBranchFees())
  }, [dispatch])

  return (
    <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <ConfirmProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
            {content}
        </LocalizationProvider>
      </ConfirmProvider>
    </ThemeProvider>
    </QueryClientProvider>
  );
}

const useKey = localStorage.getItem("uniKey");

export const EntryPoint = () => {
  return useKey !== null ? (
    <HelmetProvider>
      <Toaster position='top-center' toastOptions={{
        error: {
          duration: 4000
        }
      }} />
      <Provider store={store}>
        <Authenticated>
          <SidebarProvider>
            <BrowserRouter>
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <App />
            </BrowserRouter>
          </SidebarProvider>
        </Authenticated>
      </Provider>
    </HelmetProvider>
  ) : (
    <Provider store={store}><BrowserRouter><Login /></BrowserRouter></Provider>
  )
}

export default App;
